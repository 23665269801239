.InputGroupTeco {
 display: flex;
 //width: 50%
 height: auto;
 justify-content: center;
 align-items: center; }

.inputgroupteco {
 padding: 2% !important;
 margin: 0 !important;
 height: auto !important;
 border-radius: .25rem 0 0 .25rem !important; }
.tecogroup-text {}

.tecogroup-prepend {
 background-color: #e9ecef;
 border: 1px solid #ced4da;
 border-radius: 0 .25rem .25rem 0 !important;
 text-align: center;
 width: 23%;
 display: flex;
 justify-content: center;
 align-items: center; }
.tecogroup-prependPostigel {
 padding: 2% !important;
 background-color: #e9ecef;
 border: 1px solid #ced4da;
 border-radius: 0 .25rem .25rem 0 !important;
 text-align: center;
 width: 29%;
 padding: 6px 0 6px 0 !important;
 font-size: 1vw;
 justify-content: center; }
.ArrowsMore {
 background: white;
 width: auto;
 height: 100%;
 display: flex;
 flex-direction: column;
 padding: 1%;
 cursor: pointer;
 //padding: 2%
 border: 1px solid #ced4da; }
.iconmore {
 font-size: 1vw !important;
 color: #49358f !important; }
.InputGroupTecoDynamicWidth {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center; }
.firstLastPulses {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 padding: 2%;
 color: #3f4651; }

.invalid-feedback {
 text-align: center; }
.firstLastPulsesColumn {
 display: flex;
 width: 50%;
 height: auto;
 justify-content: center;
 align-items: center;
 flex-direction: column; }
/*MVL*/
@media (max-width: 480px) {
 .iconmore {
  font-size: 11px !important; }
 .ArrowsMore {
  padding: 2%; }
 .tecogroup-prependPostigel {
  padding: 6px 0 6px 0 !important;
  font-size: 11px; } }
