.pillcardsP {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-around;
 align-items: center; }
.pillCardP {
 width: 44%;
 display: flex;
 height: auto;
 justify-content: center;
 align-items: center;
 background: #4180ff;
 color: white;
 border-radius: 20px;
 margin-bottom: 6%;
 font-weight: 500;
 font-size: 0.8vw;
 margin-left: 1%;
 margin-right: 1%; }
@media (max-width: 480px) {
 .pillCardP {
  font-size: 7.5px;
  width: 49%;
  margin-left: 0;
  margin-right: 0; } }

