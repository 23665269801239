//////Animation not show timer:
@import "./../../../../App.sass";
.subrayar {
 font-weight: 600;
 color: #2b2054;
 font-size: 1.2vw; }
.cursor {
  cursor: pointer; }
.hideMe {
  -webkit-animation: cssAnimation 3s forwards;
  animation: cssAnimation 3s forwards; }
@keyframes cssAnimation {
    to {
     width: 0;
     height: 0;
     overflow: hidden; } }
@-webkit-keyframes cssAnimation {
    to {
     width: 0;
     height: 0;
     visibility: hidden; } }
////
.IconosFila {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 align-items: center;
 padding: 4%; }
.infoTokens {
 display: flex;
 width: 26% !important;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.datacopy {
 text-align: center;
 width: 100%;
 height: auto;
 display: flex;
 flex-direction: row; }
.copy {
 text-align: center;
 padding: 2%; }
.keyslist {
 width: 5vw;
 cursor: pointer;
 height: 5vw; }
.titleform {
 display: flex;
 width: auto;
 height: auto;
 color: #868e96; }
.headToken {
 width: 100%;
 display: flex;
 height: auto;
 justify-content: center;
 align-items: center; }
.Iconref {
 width: 4vw; }
.messageCopied {
 display: flex;
 width: 100%;
 height: 100%;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 } //padding: 2%
.ButtonsShare {
 display: flex;
 width: 90%;
 height: auto;
 justify-content: space-between;
 align-items: center;
 padding: 2%; }
.Token {
 display: flex;
 width: 97%;
 height: auto;
 flex-direction: column;
 } //min-height: 29vw
.Inputsa50 {
 width: 40%; }
.PadreToken {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 flex-direction: column; }
.ButtonToken {
 display: flex;
 width: 18%;
 background: $ColorPrincipal;
 color: white;
 border-radius: 2.5em;
 cursor: pointer;
 margin: auto;
 padding: 1%;
 align-items: center;
 justify-content: center;
 border: 0;
 margin-top: 4%; }
.InputFlex {
 display: flex;
 width: 100%;
 height: auto;
 margin-bottom: 1%;
 justify-content: center;
 flex-direction: column; }
.tokenInput {
 display: flex;
 width: 100% !important;
 } //font-size: 1rem !important
.MensajeB {}

.ButtonAndHistoric {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.ButtonAndHistoric1 {
 width: 80%;
 height: auto; }
.ButtonAndHistoric2 {
 width: 20%;
 height: auto; }
.Separator {
 border: 0;
 clear: both;
 display: block;
 width: 96%;
 background-color: $ColorPrincipal;
 height: 1px;
 margin: 3%; }
.Separator2 {
 border: 0;
 clear: both;
 display: block;
 width: 96%;
 background-color: $ColorPrincipal;
 height: 1px;
 margin: 0%; }
.Separator3 {
 border: 0;
 clear: both;
 display: block;
 width: 96%;
 background-color: $ColorPrincipal;
 height: 1px;
 margin: 0; }
.url {
 width: 75% !important; }
.DescriptionToken {
 height: 20vh !important; }
.Error {
  color: red; }
.page-item.active .page-link {
 background: $ColorPrincipal !important; }
.NameKey {
 width: 100% !important; }
.DaysKey {
 width: 30.1% !important; }
.ButtonsShare {
 padding: 0; }
.copyIcon {
 width: 30%; }
.iconsshare > svg {
 border-radius: 50%; }
.ButtonsKey {
 display: flex;
 width: 45%;
 height: auto;
 align-items: center; }
.ContentInsidePopUp {
 height: auto; }
.Separator3.buttonss {
 margin: 1%;
 } //min-height: 55vw
/*MVL*/
@media (max-width: 480px) {
    .infoTokens {
     display: flex;
     width: 90% !important;
     height: auto;
     justify-content: space-between;
     align-items: center; }
    .Separator3.buttonss {
     margin: 0; }
    .ButtonsKey {
     width: 100%;
     height: auto;
     align-items: center;
     justify-content: center; }
    .subrayar {
     font-size: 13px; }
    .PadreToken {
     flex-direction: column;
     padding: 0% 2% 0% 2%;
     align-content: center;
     margin-top: 3%;
     margin-bottom: 3%; }
    .Inputsa50 {
     width: 100%;
     height: auto;
 }     //max-height: 2vw
    .InputFlex {
     flex-direction: column; }
    .NameKey {
     width: 50% !important;
     margin: auto; }
    .DescriptionToken {
     height: 16vw !important;
     -webkit-column-break-inside:avoid {} }
    .ButtonToken {
     padding: 2%;
     margin-top: 1%; }
    .DaysKey {
     width: 51% !important;
     margin-left: 5%; }
    .keyslist {
     width: 14vw;
     height: 14vw;
     max-height: 14vw; }
    .titleform {
     width: auto;
     font-size: 11px; }
    .ContentInsidePopUp {
     min-height: none;
     height: 90vw;
     overflow-y: scroll; }
    .copy {
     padding: 0;
     width: 100%; }
    .ButtonsShare {
     display: flex;
     width: 90%;
     height: auto;
     justify-content: space-between;
     align-items: center;
     padding: 0; }
    .Iconref {
      width: 13vw; }
    .Token {
     font-size: 11px; } }
