@import "./../../../../App.sass";

.PadreAccordion {
 border-style: solid;
 border-width: 1px;
 width: 98%;
 height: auto;
 background-clip: border-box;
 border: 1px solid #dee2e6;
 border-radius: .25rem;
 align-items: center;
 margin-left: 0.5%; }
.togglerOutputs {
 display: flex;
 justify-content: start;
 align-items: inherit;
 padding: 1%;
 background-color: $ColorPrincipal;
 color: white;
 text-shadow: 1px 2px #1f2d3d;
 border-radius: .25rem !important;
 cursor: pointer; }
.titleaccordion {
 width: 70%; }
.spinneraccordion {
 width: 30%;
 height: auto; }
.AccordionChildControled:hover {
 background: #AF99FB;
 color: white;
 margin-top: 1%;
 margin-bottom: 1%; }

.iconacco {
 margin-left: 1%;
 margin-right: 1%;
 font-size: 1vw !important;
 color: white !important;
 } //Contenido
.ContentAccordion {
 border: none !important;
 background: white;
 color: black;
 text-shadow: none !important;
 padding:  0; }
.FilaDesplegable {
 display: flex;
 width: 100%;
 align-items: baseline;
 justify-content: space-between;
 flex-direction: column;
 padding: 2%; }
/*MVL*/
@media (max-width: 480px) {
 .togglerOutputs {
  width: 100%; }
 .iconacco {
  margin-left: 1%;
  margin-right: 1%;
  font-size: 11px !important; } }
