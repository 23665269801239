.titleResetPostigel {
 display: flex;
 width: 70%;
 height: auto;
 font-weight: 400;
 margin-left: 8%;
 margin-top: 2%;
 font-size: 1.2vw; }
.chooseSizeReset {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 justify-content: space-evenly;
 padding: 2%;
 margin-bottom: 2%; }
.cardReset {
 display: flex;
 width: 42%;
 flex-direction: row;
 height: auto;
 justify-content: space-evenly;
 align-items: center;
 color: black;
 color: #8493a5;
 box-shadow: 2px 3px 7px #dee2e6;
 border-radius: 0.25rem;
 cursor: pointer;
 background: #f9fafc;


 height: 12vw; }
.cardReset:hover {
 background: #dee2e6;
 font-weight: 400; }
.cardResetChoose {
 display: flex;
 width: 42%;
 flex-direction: row;
 height: auto;
 justify-content: space-evenly;
 align-items: center;
 color: black;
 color: #8493a5;
 box-shadow: 2px 3px 7px #dee2e6;
 border-radius: 0.25rem;
 cursor: pointer;
 background: #dee2e6;

 height: 12vw; }
.RecipientResetPostigel {
 display: flex;
 width: 45%;
 height: 84px; }
.RecipientResetPostigelsmall {
 display: flex;
 width: 35%;
 height: 84px;
 margin-left: 0; }
.margin2postigel {
 margin-left: 7%; }
.textSizeResetPostigel {
 display: flex;
 width: 44%;
 height: auto;
 justify-content: center;
 align-items: center;
 color: #46484a;
 /*MVL*/ }
@media (max-width: 480px) {
 .textSizeResetPostigel {
  display: flex;
  width: 100%;
  height: auto; }
 .margin2postigel {
  margin-left: 6%; }
 .titleResetPostigel {
  margin-left: 6%;
  margin-top: 0;
  font-size: 13px; }
 .cardReset {
  width: 45%;
  margin-left: 1%;
  height: auto;

  margin-bottom: 1%;
  flex-direction: column;
  font-size: 13px;
  height: 38vw; }

 .cardResetChoose {
  width: 45%;
  margin-left: 1%;
  height: auto;

  margin-bottom: 1%;
  flex-direction: column;
  height: 38vw; }
 .chooseSizeReset {
  margin-top: 4%;
  margin-bottom: 4%; }
 .RecipientResetPostigel {
  width: 126px;
  height: 84px;
  margin-left: 35%; }
 .RecipientResetPostigelsmall {
  width: 116px;
  height: 88px;
  margin-left: 7%; } }

