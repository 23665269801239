@import "./../../../../../../../App.sass";
.smallb {
 width: 9% !important; }
.ButtonandInfoAdvanced {
 display: flex;
 width: 20%;
 height: auto; }
.TitleandProandPreRiego {
 display: flex;
 width: 92%;
 height: auto;
 justify-content: center;
 align-items: center; }
.OutputsSelect {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }
.categoryOutputF {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 } //flex-direction: column
.messageclockInfo {
 color: black;
 text-align: center; }
.ViewInterfaceSubroutinesPart1 {
 display: flex;
 width: 93%;
 height: auto;
 justify-content:  space-between;
 align-items: center;
 margin-top: 0.5%;
 flex-wrap: wrap; }
.OutputsdViewSubroutines {
 width: 50%;
 display: flex;
 height: auto;
 flex-direction: column;
 justify-content: space-between; }
.cut {
 flex-wrap: wrap; }
.OutputI {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.TitleCard {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 text-align: center;
 color: gray; }
.Big {
 font-weight: 600; }
.TitleCard.Big.Margins {
 margin-top: 10px; }
.CardsSubroutine {
 display: flex;
 flex-direction: column;
 background: white;
 width: 8rem;
 height: 9rem;
 align-items: center;
 justify-content: start;
 font-weight: 600;
 border-radius: 0.25rem;
 border-width: 2px;
 border-style: solid;
 border-color: #716f79;
 box-shadow: 2px 0.1vw 0px 1px #575659;
 padding: 1%;
 color: #8493a5; }
.CardsSubroutine.Notified {
 border-color: green; }
.CardsSubroutine.NotNotified {
 border-color: orange; }
.CardsSubroutine.Deleted {
 background: rgb(255, 150, 150); }
.Scroll {
 display: flex;
 width: 100%;
 height: 23vh;
 align-items: center;
 overflow-x: scroll;
 flex-direction: column;
 flex-wrap: wrap;
 overflow-y: hidden; }
.HourCard {
 font-weight: 600; }
.outputpill {
 width: 1vw;
 height: 1vw;
 justify-content: center;
 align-items: center;
 margin: 0;
 background-color: $ColorPrincipal !important;
 color: white;
 margin: 1%;
 border-radius: 50%; }
.outputpilldragodrop {
 width: 1rem;
 height: 1rem;
 justify-content: center;
 align-items: center;
 margin: 0;
 background-color: $ColorPrincipal !important;
 color: white;
 margin: 1%;
 border-radius: 50%;
 padding-bottom: 1.2rem; }
.OutputsCardSubroutine {
 display: flex;
 width: 100%;
 height: 4vw;
 justify-content: center;
 align-items: center;
 flex-wrap: wrap; }
.badge-primary2 {
 width: 100%;
 color: white;
 margin: 1%;
 display: flex;
 justify-content: center;
 min-height: 4rem; }
.Salidas {
 width: 76%;
 height: auto;
 display: flex;
 flex-wrap: wrap; }

.justify {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-evenly;
 align-content: center;
 padding: 1%; }
.NameCardSubRoutine {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-around;
 align-items: center; }
.ICoCardDeactivated {
 width: 1.4vw;
 height: auto;
 fill: rgb(113,111,121); }
.ICoCard {
 width: 1.4vw;
 height: auto;
 fill: $ColorPrincipal !important;
 cursor: pointer; }
.outputvi {
 font-size: 0.7vw; }
@media (max-width: 480px) {
 .Salidas {
  font-size: 11px; }
 .smallb {
  width: 25% !important; }
 .messageclockInfo {
  margin-left: 2%;
  font-weight: 600;
  color: #575659;
  width: auto;
  display: flex;
  justify-content: center; }
 .TitleCard {
  font-size: 13px; }
 .OutputsdViewSubroutines {
  width: 100% !important; }
 .SelectTimeProgramS {
  width: 100% !important; }

 .outputvi {
  font-size: 8px; }
 .outputpill {
  width: 3.2vw;
  height: 3.2vw;
  justify-content: center;
  align-items: center;
  background-color: $ColorPrincipal !important;
  color: white;
  border-radius: 50%; }
 .outputpilldragodrop {
  padding-bottom: 0rem; }
 .ICoCard {
  width: 5vw; }
 .badge-primary2 {
  padding: 0;
  width: 89%;
  margin-top: 0vw; }
 .ButtonandInfoAdvanced {
  width: 28%; } }

