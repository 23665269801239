.barpostigel {
 width: 100% !important;
 height: auto; }
.rechartLiquido {
 width: 100%;
 height: 4vw;
 display: flex;
 justify-content: flex-end;
 align-items: center; }
.rechartLiquido > .recharts-responsive-container {
 width: 40% !important; }
.baterypopupfather {
 width: 103% !important;
 height: 17vw !important; }

.statisticsfather {
 min-height: 41vh !important;
 min-width: 22vw !important; }

.viewLittle {
 width: 15.5vw !important;
 height: 14vw !important;
 margin-left: -1.8vw; }
.titlebatery {
 display: flex;
 height: auto;
 align-items: center;
 width: 91%;
 height: auto; }

.daybatery {
 font-weight: 600;
 color: #444444; }
.rechartlinefatherLiquid {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: flex-end;
 align-items: center; }
.titlecardSavePostigel {
 font-size: 0.8vw; }
.valuecardSavePostigel2 {
 font-size: 1.2vw;
 font-weight: 500; }
.elementTitlebatery1 {
 width: 50%; }
.elementTitlebatery {
 width: 50%;
 text-align: center; }
//MVL
@media (max-width: 480px) {
 .baterypopupfather {
  width: 111% !important;
  margin-top: 7%;
  height: 52vw !important; }
 .statisticsfather {
  min-height: 40vh !important;
  min-width: 100vw !important;
  margin-top: 0; }

 .viewLittle {
  width: 66% !important;
  height: 41vh !important;
  margin-top: 0;
  margin-left: -9.8vw; }
 .rechartLiquido > .recharts-responsive-container {
  width: 47% !important; }
 .rechartLiquido {
  height: 19vw; }
 .recharts-surface {
  display: flex;
  width: 100%;
  justify-content: flex-end; }
 .recharts-wrapper {
  margin: 0 auto; }
 .titlecardSavePostigel {
  font-size: 13px; }
 .valuecardSavePostigel2 {
  font-size: 22px; }

 .titlebatery {
  flex-direction: column;
  width: 100%; }
 .elementTitlebatery1 {
  width: 100%; }

 .elementTitlebatery {
  width: 100%;
  text-align: center;
  padding: 2%;
 } }  //align-items:

