.numberCircle {
  border-radius: 50%;
  width: 2.9rem;
  min-width: 2rem;
  height: 2.9rem;
  min-height: 2rem;
  font-size: 1.3rem;
  padding: 0.1rem;
  text-align: center;
  color: white;
  border: 0.3rem solid;


  -webkit-touch-callout: none; // Safari
  -webkit-user-select: none; // Chrome
  -moz-user-select: none; // Firefox
  -ms-user-select: none; // Internet Explorer/Edge
  user-select: none;

  background: linear-gradient(to top,  #fff 50%, transparent 50%);
  background-size: 110% 250%;
  background-position: top left;
  transition: background .7s ease-out;

  &.active {
    background-color: rgb(27,185,52); }
  &.deactivated {
    background-color: rgb(147, 18, 21); }
  &.transition {
    background-color: #6C757D; }
  &.pressed {
   background-position: bottom left; } }


@keyframes toWaitingBackground {
    to  {background-color: #7e42f3}; }
