@import "./../../../App.sass";
.PicPlug3 {
  width: 15vw;
  height: 15vw;
  fill: red;
  background: blue; }
.childCardDevices2 {
  display: flex;
  width: 41%;
  height: auto;
  align-items: center;
  padding: 1%;
  justify-content: space-between; }
.ConnectionIcon {
  width: 6vw;
  height: auto;
  cursor: pointer;
  filter: drop-shadow(0px 3px 4px #6c757d);
  margin-left: 12%;
  margin-bottom: 18%; }
.ConnectionIcon.Connected {
  fill: $ColorConnectionIconConnected; }

.ConnectionIcon.Disconnected {
  fill: $ColorConnectionIconDisconnected; }
.ConnectionIcon.Transition {
  fill: $ColorConnectionIconTransition; }


.ContentCard {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-around;
  position: relative; }
.Notterminals {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%; }

.TitleTerminal {
  color: white;
  background: $ColorCardTitles;
  padding: 2%;
  width: 100%;
  height: auto;
  font-weight: 400;
  display: flex;
  justify-content: space-around;
  align-items: center; }
.Title_Descripction {
  font-weight: 500;
  padding: 2%; }
.ContentImageFather {
  //max-width: 13vw
  cursor: pointer;
  margin: 0.8%;
  color: $Color_Letras;
  font-weight: 100;
  //font-size: 2vw
  //display: flex
  //align-items: center
  //flex-wrap: wrap
  //align-content: flex-start

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
.LedSVG {
  cx: 1vw;
  cy: 1vw;
  r: 1vw;
  filter: drop-shadow(300px 100px 400px rgba(8,252,114,1));
  box-shadow: 43px 21px 73px 0px rgba(8,252,114,1) {
    //stroke: white
 } }    //stroke-width: 2
.descriptionTerminals {
  display: flex;
  width: auto;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.IconSVG {
  width: 15vw;
  height: 15vw; }
.TitleTerminal {
  height: 15vw; }
.TitleTerminaldevices {
  display: flex;
  width: 100%;
  height: auto !important;
  color: white;
  background: $ColorCardTitles;
  padding: 1%;
  font-weight: 400;
  align-items: center;
  justify-content: space-between; }

.TitleTerminaldevices2 {
  display: flex;
  width: 80%;
  height: auto;
  align-items: center;
  font-size: 13px; }

.TerminalWithSensorCardContainer {
  position: relative;
  padding-top: 3.3rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center; }

.TerminalWithSensorCardImage {
  //Cambiar tamaño cuadrado:
  width: 12vw;
  height: 20vh;
  cursor: pointer;
  display: flex;
  justify-content: center;
  pointer-events: none;
  border-radius: 19%; }

.TerminalWithSensorCardSensorsContainer {
  //position: absolute
  width: 65%;
  height: 2vh;

  display: flex;
  //flex-direction: column-reverse
  flex-wrap: wrap;
  align-content: flex-end;
  margin-top: 1vh;

  padding: 0.4%;
  top: 5%;
  left: 0%;
  //max-width: 8vw
  padding-left: 0.4em; }

.TerminalWithSensorCardOnlineContainer {
  position: absolute;
  display: flex;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  top: 4vh;
  right: -3em; }


.TerminalWithSensorCardImage.Content1 {}

.TerminalWithSensorCardImage.Content2 {
  transform: scaleX(-1); }
.TerminalWithSensorCardImage.Content4 {
  transform: rotate(180deg);
  transform: scaleY(-1); }
.TerminalWithSensorCardImage.Content5 {
  transform: rotate(270deg);
  transform: scaleX(-1); }
.TerminalWithSensorCardImage.Content7 {
  transform: rotate(90deg);
  transform: scaleX(-1); }
.TerminalWithSensorCardImage.Content3 {
  transform: rotate(180deg); }
.TerminalWithSensorCardImage.Content6 {
  transform: rotate(90deg); }
.TerminalWithSensorCardImage.Content8 {
  transform: rotate(-90deg); }

/*MVL*/
@media (max-width: 480px) {
 .Notterminals {
  font-size: 16px; }
 .ContentImageFather {
  width: 30%;
  height: auto;
  margin: 0% 2% 2% 1%; }
 .descriptionTerminals {
  height: 100%;
  width: 47vw; }
 .LedSVG {
  cx: 2vw;
  cy: 2vw;
  r: 2vw; }
 .TitleTerminal {
  height: 16vw !important; }
 .Title_Descripction {
  margin-right: 0; }
 .ConnectionIcon {
  width: 18vw;
  height: 12vw; }
 .ContentCard {
  height: 100%; }
 .TerminalWithSensorCardContainer {
  padding-top: 1.2rem; }

 .TerminalWithSensorCardImage {
  //Cambiar tamaño cuadrado:
  width: 20vw;
  height: 20vw; }
 .TerminalWithSensorCardOnlineContainer {
  padding: 1.5%;
  right: -1em; } }

