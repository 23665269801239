@import "./../../../../../../../../App.sass";
.buttonAdvancedF {
 display: flex;
 width: 100%;
 height: auto;
 margin-bottom: 3%;
 align-items: center;
 justify-content: flex-end;
 margin-right: 7%;
 font-size: 0.8vw;
 cursor: pointer; }
.buttonAdvanced {
 display: flex;
 width: auto;
 background: $ColorPrincipal;
 color: white;
 border-radius: 2.5em;
 padding: 0% 5% 0% 4%;
 border: 0; }
.tworowsdeactivated {
 display: flex;
 width: 100%;
 height: 3vw;
 justify-content: space-evenly;
 align-items: center;
 flex-direction: column; }
.twocolums {
 width: 100%;
 display: flex;
 flex-direction: column; }
.Deactivate50 {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }
.titlehourcard {
 display: flex;
 width: 100%;
 height: auto; }

.titlehourpr {
 width: 100%;
 justify-content: space-around;
 display: flex;
 align-items: center;
 font-size: 0.7em; }

/*MVL*/
@media (max-width: 480px) {
 .tworowsdeactivated {
  height: 11vw; }
 .titlehourpr {
  font-size: 9px; }
 .titlecardsensor {
  font-size: 9px; }
 .buttonAdvancedF {
  font-size: 8px; } }


