.IcoPostigel {
 width: 3vw;
 height: auto; }
.IcoPostigelGraphics {
 width: 2.4vw;
 height: auto; }
.IcoPostigelpng {
 width: 2vw;
 height: auto; }
.IcoPostigelSave {
 width: 3vw;
 height: auto; }
.IcoPostigelsub {
 width: 1.5vw;
 height: auto; }

.recharts-responsive-container {
 display: flex; }
.GraficoLiquidoPostigel > .recharts-responsive-container {
 width: 100% !important;
 height: 10vw !important; }
.rechartConfig > .recharts-responsive-container {
 width: 100% !important;
 height: 9vw !important; }
.IcoPostigel2 {
 width: 20px;
 height: auto; }
.IcoPostigelapp {
 width: 2vw;
 height: auto; }
.inputPostigel {
 width: 100%;
 display: flex;
 height: auto;
 justify-content: center; }
.inputPostigelchild {
 width: 76%; }
.descriptionInputPostigel {
 width: 92%;
 height: auto;
 text-align: center; }
.descriptioncardpostigel {
 width: 76%;
 height: auto;
 display: flex;
 justify-content: center;
 flex-direction: column;
 align-items: center; }
.descriptioncardpostigelc {
 width: 80%;
 height: auto;
 text-align: center; }
.valueCardPostigel {
 font-size: 1.2vw;
 font-weight: 600;
 color: black; }
.icoConfPostigel {
 margin-top: 5%; }
.icoconfpostigel {
 font-size: 2.5vw !important; }
.postigelcenter {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }
.InputAlarmcardPosti > .postigelcenterButton {
 padding: 5%; }
.postigelcenterButton {
 display: flex;
 width: 92%;
 height: auto;
 justify-content: center;
 align-items: center; }
.noticePostigel {
 display: flex;
 width: 55%;
 height: auto;
 justify-content: space-between;
 align-items: center;
 margin-left: 22%;
 padding: 1%; }

.noticeIco {
 fill: red;
 width: 2vw; }
.bg-successPostigel {
 background-color: #75A3FF !important; }
.TitleMultipleStatics {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 padding: 1%; }
.titleSelectorDay {
 display: flex;
 width: 80%;
 height: auto;
 justify-content: center;
 align-items: center; }
.cardPostigelAlarmIco {
 width: 2vw;
 height: auto; }
.cardPostigelClickIco {
 width: 1.5vw;
 height: auto; }
.Alarmacard {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 justify-content: space-between;
 padding: 2%; }
.textAlarmcardPosti {
 width: 58%;
 display: flex;
 justify-content: center;
 align-items: center; }
.fatherAlarm {
 display: flex;
 width: 100%;
 height: auto;
 flex-direction: column; }
.InputAlarmcardPosti > .Error {
 //auto center
 display: contents; }
.InputAlarmcardPosti {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 flex-direction: column; }
.InputAlarmcardPostiC {
 display: flex;
 width: 64%;
 height: auto;
 justify-content: center;
 align-items: center; }

.icoDeletePostigel {
 color: red !important; }

.DivButtonsPostigelAlarm {
 margin-top: 1%;
 margin-bottom: 2%;
 width: 36%;
 height: auto;
 display: flex;
 justify-content: flex-;
 left: 60.5vw; }
.IndicatorClickPostigel {
 display: flex;
 width: 91%;
 height: auto;
 justify-content: flex-end;
 align-items: center;
 padding: 1%;
 cursor: pointer; }
.iconAlarmPostigel {
 display: flex;
 width: 20%;
 height: auto;
 justify-content: center;
 align-items: center; }
.closeAlarmPostigel {
 display: flex;
 width: 13%;
 height: auto;
 justify-content: center;
 align-items: center; }
/*MVL*/
@media (max-width: 480px) {
 .DivButtonsPostigelAlarm {
  width: 64%;
  justify-content: center;
  margin-bottom: 4%;
  margin-top: 4%; }
 .InputAlarmcardPosti {
  justify-content: start; }
 .icoDeletePostigel {
  color: red !important; }

 .InputAlarmcardPostiC {
  display: flex;
  width: 50%;
  height: auto;
  margin-left: 0; }
 .cardPostigelAlarmIco {
  width: 24px;
  height: auto; }
 .cardPostigelClickIco {
  width: 20px;
  height: auto; }
 .TitleMultipleStatics {
  flex-direction: column; }
 .IcoPostigelpng {
  width: 23px;
  height: auto; }
 .IcoPostigelSave {
  width: 51px;
  height: auto; }
 .panelConfPostigel {
  display: flex;
  width: 100%;
  height: 145vw;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; }
 .postigelcenterButton {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin-top: 2%; }
 .icoConfPostigel {
  margin-top: 0;
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center; }
 .icoconfpostigel {
  font-size: 22px !important; }
 .IcoPostigel {
  width: 34px; }
 .IcoPostigelapp {
  width: 26px; }
 .IcoPostigelGraphics {
  width: 28px;
  height: auto;
  padding: 6%; }
 .IcoPostigel2 {
  width: 20px;
  height: auto; }
 .IcoPostigelsub {
  width: 20px; }

 .inputPostigelchild {
  width: 90%; }
 .descriptioncardpostigel {
  width: 74%;
  align-items: baseline; }
 .valueCardPostigel {
  font-size: 13px; }
 .descriptionInputPostigel {
  width: 100%;
  padding: 1%; }
 .GraficoLiquidoPostigel > .recharts-responsive-container {
  width: 55% !important;
  height: 25vw !important; }
 .rechartConfig > .recharts-responsive-container {
  height: 17vw !important; }
 .noticeIco {
  width: 18px; }
 .noticePostigel {
  display: flex;
  width: 65%;
  height: auto;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 12%;
  padding: 1%; } }
