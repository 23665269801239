.InputCalibratePosti {
 display: flex;
 width: 48%;
 height: auto;
 justify-content: center;
 align-items: center;
 margin-left: 28%; }
.inputCalibrate {
 width: 33%;
 height: auto;
 justify-content: center;
 align-items: center; }
.titlecalibrateinput {
 text-align: center; }
.IcoFinalCalibrate {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }
.bodyCalibratePosti {
 height: 22vw;
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 align-items: center; }
.panelNotificationPostigel {
 display: flex;
 width: 100%;
 height: 16vw;
 justify-content: space-around;
 align-items: center;
 flex-direction: column;
 padding: 2%; }
.ErrorconfPostigel {
 display: flex;
 color: red;
 font-size: 1vw;
 margin-top: 2%;
 width: 82%;
 height: auto;
 flex-direction: column;
 margin-left: 3%; }
.ErrorconfPostigelc {
 margin-top: 3%;
 margin-bottom: 2%; }
.IcoErrorPostigel {
 fill: red;
 width: 3vw; }
.ErrorfPostigel {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 justify-content: center; }
/*MVL*/
@media (max-width: 480px) {
 .IcoErrorPostigel {
  width: 21vw; }
 .ErrorconfPostigel {
  font-size: 12px;
  display: flex;
  margin-top: 6%;
  width: 60%;
  margin-left: 3%;
  text-align: justify;
  font-weight: 600;
  flex-direction: column; }
 .panelNotificationPostigel {
  height: 72vw;
  justify-content: space-around; }
 .InputCalibratePosti {
  display: flex;
  width: 78%;
  height: auto;
  margin-left: 16%; }
 .titlecalibrateinput {
  text-align: center;
  font-size: 13px !important; }
 .fathercalibrate {
  padding: 4%; }
 .bodyCalibratePosti {
  height: 124vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  line-height: 9.2vw; } }
