.BoxDialogFather {
 width: 90%;
 position: absolute;
 //background: rgba(208,216,226,.28)
 height: auto;
 z-index: 9999; }
.BoxDialogFather.Clock {}
.ImageHelpPrograms {
 width: 17vw;
 height: auto;
 filter: drop-shadow(2px 6px 5px #868e96); }
.ImageHelpPrograms2 {
 width: 8vw;
 height: auto;
 filter: drop-shadow(2px 6px 5px #868e96); }
.BoxDialog {
 width: 17vw;
 height: 5vw;
 filter: drop-shadow(2px 6px 5px #868e96); }
.BoxDialogFatherContent {
 position: relative;
 left: 0.5vw;
 top: -3.8vw; }
.alldisplayclickOnClose {
 position: relative;
 width: 100%;
 height: auto;
 left: 10%; }
.PizarraFather {
 position: absolute;
 width: 100%;
 height: 100vh;
 //background: #ced4da75
 background: rgba(208,216,226,.28); }

.BoxdialogModal {
 position: absolute;
 z-index: 100;
 left: 0;
 top: 0;
 width: auto;
 height: auto;
 overflow: auto;
 margin-left: 19%;
 overflow-x: hidden; }

.widthbox1 {
 width: 25vw; }
.widthbox2 {
 top: -3vw;
 left: 4%; }
/*MVL*/
@media (max-width: 480px) {
 .widthbox1 {
  width: 60vw; }
 .widthbox2 {
  left: -28%;
  top: -8vw; }
 .BoxDialog {
  width: 59vw;
  height: 15vw; }
 .ImageHelpPrograms {
  width: 59vw;
  height: auto; }
 .ImageHelpPrograms2 {
  width: 29vw;
  height: auto; }
 .icoDialogbox {
  font-size: 11px !important; }
 .BoxDialogFatherContent {
  position: absolute;
  left: 3.5vw;
  top: 1.2vw; } }
