.DayweeksTitleF {
  display: flex;
  width: 61%;
  height: auto;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1%; }
.DayweeksTitle {
  width: auto;
  height: auto;
  color: #868e96; }
.DayP {
  display: flex;
  width: 10%;
  height: auto;
  color: white;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  text-shadow: 14 28 black;
  text-shadow: 0px 0px 9px #151515;
  padding: 1%;
  cursor: pointer; }
.DayP.deactive {
  background: #d1f3d1b8; }
.DayP.active {
  background: #31da31b8; }
.DayP.activeUnclick {
  background: #9ecca5; }
.positionButtonSelectAllDays {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-evenly;
  align-items: center; }
.ContentSelectDays {
  display: flex;
  width: 87%;
  height: auto;
  justify-content: space-between;
  align-items: center; }
.buttonselectalldaysfather {
  display: flex;
  width: 23%;
  height: auto; }
@media (max-width: 480px) {
 .DayweeksTitleF {
  display: flex;
  width: 100%;
  padding: 0 !important;
  margin-left: 1%;
  margin-bottom: 0; }
 .DayP {
  padding: 2%; }
 .buttonselectalldaysfather {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: flex-end;
  align-items: center; }
 .positionButtonSelectAllDays {
  flex-direction: column-reverse;
  padding: 2%; }
 .ContentSelectDays {
   width: 100%;
   margin-bottom: 4%; }
 .rangeSliderWrap {
   margin-top: 4%; } }
