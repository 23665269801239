@import "./../../../App.sass";
.classprogramsfather {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: start;
 align-items: center;
 flex-direction: column; }

.ViewInterfacePrograms {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 overflow: hidden; }
.ViewInterfaceProgramsPart1 {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 align-items: center;
 flex-direction: column; }
.ViewInterfaceProgramsPart2 {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }
.ContentSliderPrograms {
 display: flex;
 width: 50%;
 height: auto;
 justify-content: center;
 align-items: center;
 padding: 2%; }
.HeaderPrograms {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }
.SelectTimeProgram {
 width: 60%;
 height: auto; }
.SelectTimeProgramS {
 width: 40%;
 height: auto; }
.TitleProgram {
 display: flex;
 width: 66%;
 height: auto;
 align-items: center;
 font-size: 1.5vw;
 color: #727d88; }
.InputnameProgram {
 display: flex;
 width: 61%;
 height: auto;
 justify-content: end;
 align-items: center;
 padding: 0.5%;
 margin-top: 0;
 margin-bottom: 0; }
.InputnameProgram2 {
 display: flex;
 width: 60%;
 height: auto;
 justify-content: space-between;
 align-items: center;
 padding: 0.5%;
 margin-top: 2%;
 margin-bottom: 2%;
 align-items: center; }
.DaysComponent {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 flex-direction: column; }
.ButtonInfraPrograms {
 font-size: 1vw;
 display: flex;
 width: 19%;
 height: auto;
 background: $ColorPrincipal;
 color: white;
 border-radius: 2.5em;
 cursor: pointer;
 padding: 4.4%;
 align-items: center;
 justify-content: center;
 max-height: 7vw;
 border: 0; }
.ButtonsSubPrograms {
 display: flex;
 width: 98%;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.dinamycClassON {
 justify-content: space-between;
 align-items: center; }
.ButtonPrograms {
 font-size: 1vw;
 display: flex;
 width: 16%;
 background: $ColorPrincipal;
 color: white;
 border-radius: 2.5em;
 cursor: pointer;
 padding: 1%;
 align-items: center;
 justify-content: center;
 border: 0;
 margin-top: 2%; }
.ButtonsPrograms {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-around;
 align-items: center; }
.ButtonsInfraPrograms {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;

 //justify-content: space-around
 align-items: center; }
.programName {
 width: 40% !important;
 padding: 2%; }
.NameKeyProgramF {
 width: 73%; }
//Fix programs view portrait Ipad
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
 .classprogramsfather {
  width: 96%; } }

/*MVL*/
@media (max-width: 480px) {
 .DaysComponenet {
  padding: 2%; }
 .NameKeyProgram {
  width: 83% !important; }
 .ButtonsSubPrograms {
  margin: 3%;
  width: 97%; }
 .ButtonsPrograms {
  margin: 3%; }
 .ButtonsInfraPrograms {
  margin: 3%;
  justify-content: center; }
 .classprogramsfather {
  align-items: center;
  flex-direction: column;
  min-height: 76vh; }
 .ButtonInfraPrograms {
  width: 28%;
  font-size: 11px;
  padding: 2%; }
 .ButtonPrograms {
  width: 28%;
  font-size: 11px;
  padding: 2%;
  margin-top: 3%; }
 .TitleProgram {
  width: 43%;
  font-size: 14px;
  justify-content: center; }
 .ViewInterfacePrograms {
  flex-direction: column;
  justify-content: space-between; }
 .ViewInterfaceProgramsPart1 {
  width: 100%; }
 .ViewInterfaceProgramsPart2 {
  width: 100%; }
 .SelectTimeProgram {
  width: 100%; }
 .InputnameProgram {
  width: 99%;
  margin: 0;
  padding: 0;
  margin-top: 1%;
  margin-bottom: 1%; }
 .InputnameProgram2 {
  width: 99%;
  margin: 0;
  padding: 0;
  margin-top: 1%;
  margin-bottom: 1%;
  justify-content: space-around; } }
