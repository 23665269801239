.PanelTypesSubprograms {
 display: flex;
 width: 100%;
 height: 20vw;
 justify-content: center;
 align-items: baseline;
 margin-top: 4%; }

.PanelFatherTypes {
 display: flex;
 width: 100%;
 //height: 31vw
 height: auto;
 flex-direction: column;
 //justify-content: center
 align-items: center;
 padding: 1%; }

.fatherTypesDropdown {
 display: flex;
 width: 29%;
 height: auto; }
.UiFatherTypes {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-around;
 align-items: center; }
.ConfigTypesOutputs {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;

 align-items: center;
 flex-direction: column;

 } //align-items: center
.ElementConfigType {
 width: 87%;
 font-size: 1vw; }
.pizarraTypes {
 display: flex;
 width: 100%;
 height: auto;
 margin-top: 3%;
 align-items: center;
 cursor: pointer;
 margin-left: 2%; }
.ChooseType {
 border-radius: 6px 6px 0rem 0rem;
 color: white;
 background-color: #4180FF; }
.icoDropddownMy_placeholder {
 display: flex;
 width: 97%;
 height: auto;
 align-items: center;
 justify-content: space-between;
 padding: 4%;
 color: #868e96; }
.RowUISelectTypes {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-around;
 align-items: center; }


.DropdownF_SelectType {
 display: flex;
 flex-direction: column;
 width: 48%;
 height: auto;
 justify-content: center;
 font-size: 1vw; }

.dropdownPanelTypeBig {
 width: 48%; }
.dropdownPanelTypeLittle {
 width: 48%; }
.widthUiMode {
 display: flex;
 width: 60%;
 height: auto;
 justify-content: center; }
.ButtonType {
 width: 14%;
 height: auto;
 margin: 1%;
 justify-content: center;
 background: #4180FF;
 border-radius: 2.5em;
 text-align: center;
 color: white;
 padding: 1%;
 font-weight: 600;
 cursor: pointer;
 font-size: 1vw; }
.cardModepulsesPerMinute {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 box-shadow: 0 1px 3px rgba(0,0,0,0.75);
 border-radius: 0em 0em 6px 6px;
 padding: 0 0 2% 0; }
//MVL
@media (max-width: 480px) {
 .RowUISelectTypes {
  padding: 2%; }
 .ElementConfigType {
  width: 100%;
  min-height: 30vw;
  font-size: 11px; }
 .PanelFatherTypes {
  //height: 131vw
  height: auto;
  padding: 0;
 }  //justify-content: space-between
 .PanelTypesSubprograms {
  height: 100vw; }
 .ConfigTypesOutputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin-top: 0;
  justify-content: center;
  align-items: center; }
 .fatherTypesDropdown {
  width: 47%;
  padding: 2%; }
 .DropdownF_SelectType {
  font-size: 10px; }
 .ButtonType {
  width: 34vw;
  font-size: 11px; }
 .icoDropddownMy_Option {
  font-size: 11px;
  padding: 6%; }
 .widthUiMode {
  display: flex;
  width: 100%; }
 .typesOutputsClass-content {
  max-height: 205vw !important; } }
