@import "./../../../App.sass";
.keysError {
 width: 33% !important; }
.datapickerdeactive {
 background: gray; }
.inputdays {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }
.table-responsive {
 max-height: 64vh;
 min-height: 34vw; }
.documentation {
 padding: 1%; }
.PositionIconHistoric {
 display: flex;
 width: 100%;
 height: 100%;
 justify-content: flex-end;
 padding: 1%; }
.circlesvg {
 background: $ColorPrincipal;
 fill: white;
 border-radius: 50%;
 width: 4.5vw;
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 2%; }
.divfrase {
 display: flex;
 justify-content: space-between;
 flex-direction: column; }
.deactive {
  background-color: gray !important; }
.Column2 {
 display: flex;
 width: 57%;
 justify-content: center; }
.TextInfo {
 width: 96%;
 margin: auto;
 height: auto;
 font-size: 1vw; }
.react-datepicker-wrapper {
 display: flex;
 width: 100%;
 justify-content: center; }
.bold {
 font-weight: 600; }
.IconsTitleToken {
 display: flex;
 width: 100%;
 height: auto;

 align-content: center;
 margin-top: 1%; }
.IconsTitleToken1 {
 display: flex;
 width: 33%;
 height: auto;
 justify-content: center;
 align-items: center; }
.IconsTitleToken2 {
 display: flex;
 width: 33%;
 height: auto;
 justify-content: center;
 align-items: center; }
.widthDatapicker {
 display: flex;
 width: 45%;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 } //margin-left: 27%
/*MVL*/
@media (max-width: 480px) {
 .widthDatapicker {
  width: 95%;
  margin-left: 2.5%; }
 .inputdays {
  width: 58%; }
 .PositionIconHistoric {
  max-height: 20vw;
  height: auto;
  width: auto;
  left: 87%; }
 .idhistoric {
  width: 20%; }
 .circlesvg {
  width: 14vw;
  height: 14vw; }

 .TextInfo {
  width: 80%;
  margin: auto;
  height: auto;
  font-size: 11px; } }
