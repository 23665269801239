
//Colores principales de la app:
//$ColorPrincipal: rgb(29,97,176)
$ColorPrincipal: #4180FF;
$ColorSecond: rgb(166,163,168);
$ColorSecondary: #7D6D9F;
$ColorDeactivated: #bdbdbd;
//$ColorCardTitles: var(--theme-menu-text-color)
//$ColorPrincipal: #33ae9a
$ColorCardTitles: $ColorPrincipal;
$Color1: rgb(249,250,252);
$Color2: white;
$Color3: #007BFF;
$Color4: rgb(193,213,219);
$Color5: #CA0404;
$DangerColor: #CA0404;
$Color_Letras: black;
$ColorHover: #007BFF;
$ColorSelect: #7c7d7e;
$ColorConnectionIconConnected: #00C9A7;
$ColorConnectionIconDisconnected: #FF9671;
$ColorConnectionIconTransition: #B0A8B9;

a {
 text-decoration: none !important; }

.fa-disabled {
  opacity: 0.6;
  cursor: not-allowed; }

.heightspinner {
 min-height: 30vw;
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center; }
.makerTitle {
 position: relative;
 display: flex;
 width: 10vw;
 justify-content: center;
 top: 40px;
 text-shadow: 1px 2px #1f2d3d; }
div.title {
 display: none; }
.helptitle {
 font-size: 1.2vw;
 font-weight: 900; }
.helpf {
 text-align: center;
 margin-bottom: 2%; }
.subhelptitle {
 font-size: 1.2vw;
 font-weight: 500;
 padding: 1%; }
.ImageCenterhelp {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 padding: 2%; }
.grouphelp {
 padding: 1%; }
.imagecontenthelp {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: flex-start; }
.imageandtextcontent {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 align-items: flex-start; }
.sizepicHelpOutputs {
 width: 9vw;
 height: auto;
 } //----
.badge-primary {
 background-color: $ColorPrincipal !important; }
.nav-link {
 cursor: pointer; }
.active2 {
 background: $ColorPrincipal !important;
 color: white !important;
 cursor: pointer;
 display: flex;
 height: 50vw;
 justify-content: center;
 align-items: center;
 height: auto;
 padding: 6px !important;
 min-width: 83px;
 text-align: center;
 border-radius: 2.5em; }
.Loadspinner2 {
 display: flex;
 height: auto;
 justify-content: center;
 align-items: center; }
.Loadspinner3 {
 display: flex;
 height: 50vw;
 justify-content: center;
 align-items: center; }
//sidebar
.BotonLogin {
 font-size: 1vw;
 background: $ColorPrincipal !important; }
.icoAirframe {
  color: white !important;
  background: $ColorPrincipal;
  padding: 2%;
  cursor: pointer;
  border-radius: 50%; }
.cards {
  width: 100%;
  height: auto;
  margin-top: {} }
.ContentMap {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
.PruebaNotselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
.buttongeneral {
  display: flex;
  width: 35%;
  background: $ColorPrincipal;
  color: white;
  border-radius: 2.5em;
  cursor: pointer;
  margin: auto;
  padding: 1%;
  align-items: center;
  justify-content: center;
  border: 0;
  margin-top: 4%; }
.buttongeneral.disabled {
  background: $ColorDeactivated; }
.Info {
 padding: 0;
 filter: invert(8%) sepia(95%) saturate(7292%) hue-rotate(2deg) brightness(92%) contrast(97%); }
.margintop {
  margin-top: 4%; }
.react-datepicker {
  display: flex; }
.react-datepicker__day--selected {
  backgrbackground-color: $ColorPrincipal !important; }
.LedShadow {
  cx: 1.5vw;
  cy: 1.5vw;
  r: 1.25vw;
  filter: drop-shadow(300px 100px 400px rgba(8,252,114,1));
  box-shadow: 43px 21px 73px 0px rgba(8,252,114,1); }
.legend {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: space-around;
  //align-items: center
  padding: 1%; }
.legendChild {
  display: flex;
  width: 30%;
  height: auto;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  text-align: center; }
.form-control {
  font-size: 1vw !important; }
.Color1C {
  fill: rgb(33,150,243); }
.Color2C {
  fill: rgb(255,87,34); }
.Color3C {
  fill: #ca8eff; }
.Color4C {
  fill: rgb(55,81,30); }
.legendcircle {
  width: 4.5vh;
  height: auto;
  cursor: pointer; }
.flexMenuChild .fa {
  color: grey;
  cursor: pointer; }
.divdropdown {
  margin-top: 5%; }
.flexMenu {
  display: flex !important; }
.ButtonMap {
  width: 80px;
  height: 50px;
  background-color: $Color1;
  cursor: pointer;
  border-radius: .25rem;
  color: $Color_Letras;
  margin: 7%;
  border: 0; }
.ButtonMap:hover {
  background-color: $ColorHover; }
.App-link {
  color: $Color1; }
.titleApp {
  font-size: 2vw;
  width: 100%;
  margin-top: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }
.Title {
  //font-size: 1.5rem
  //color: black
 }  //font-weight: 600
.Loadspinner {
  width: 100%;
  min-height: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
.LoadspinnerImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 13vw; }
.Error {
  padding: 0;
  display: flex;
  width: 100%;
  scroll-margin-top: 5vh;
  scroll-snap-margin-top: 5vh;
  //font-size: 1.2vw
  //justify-content: center
  align-items: center; }
.ButtonUI {
  display: none;
  width: 54%;
  color: black;
  display: flex;
  height: 0;
  cursor: pointer;
  visibility: hidden;
  justify-content: space-around;
  align-items: center;
  padding: 0; }

.MapPage1 {
  width: 95%;
  height: 88vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
 }  //LOGIN

.LogicPic {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; }

.pill-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 3%; }
.pill.secondary {
  cursor: pointer;
  padding: 2%;
  width: 46%;
  display: flex;
  justify-content: center;
  font-weight: 400; }
.pill.secondary.selected {
  border-radius: 6px 6px 0 0;
  color: white;
  background-color: $ColorPrincipal;
  width: 50%; }
.pill.secondary.false {
  width: 50%;
  color: black;
  border-radius: 6px 6px 0 0;
  border: 2px solid #ced4da; }
.colored {
  border-radius: 2.5em 2.5em 0rem 0rem;
  padding: 2%;
  color: white !important;
  background-color: $ColorPrincipal !important;
  cursor: pointer;
  width: 46%;
  display: flex;
  justify-content: center;
  font-weight: 900;
  text-shadow: 1px 2px #1f2d3d; }
.not_colored {
  cursor: pointer;
  width: 40%;
  text-align: center; }
.PicLogin {
  padding: 8%;
  max-height: 256px;
  max-width: 512px; }
.Title_Descripction {
  width: auto;
  display: flex;
  color: #8493a5;
  font-weight: 500;
  padding: 0% !important;
  margin-left: 0; }
.Title_Description.TriggerCard {
  width: 6rem; }

.buttonDeleteCard {
  cursor: pointer;
 }  //font-size: 2vw
.buttonselect {
  //padding: 2% !important
  cursor: pointer;
  margin: 0; }
.pildora {
  background: $ColorPrincipal;
  color: white;
  padding: 1%;
  border-radius: 2.5em;
  width: 40%;
  text-align: center; }
.notpointer {
  cursor: default !important; }
.errormap {
  margin-left: 4%; }

p.subText {
  color: $ColorSecond;
  font-size: 0.8rem; }

.period-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  flex-wrap: wrap; }

/*MVL*/
@media (max-width: 480px) {
 .pill-container {
  margin-top: 0; }
 .BotonLogin {
  font-size: 13px; }
 .buttonselect {
  padding: 1%;
  margin-top: 2%;
  margin-right: 2%; }
 .grouphelp {
  padding: 1%;
  font-size: 11px; }
 .subhelptitle {
  font-size: 11px;
  font-weight: 500;
  padding: 2%; }
 .sizepicHelpOutputs {
  width: 41vw;
  height: auto; }
 .imagecontenthelp {
  padding: 1%; }
 .imageandtextcontent {
  flex-direction: column-reverse; }
 .colored {
  padding: 0; }
 .Loadspinner {
  min-height: 94vw; }
 .buttonDeleteCard {
  cursor: pointer; }
 .ButtonUI {
  width: 31%;
  display: flex;
  visibility: visible;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center; }
 .PicLogin {
  width: 150px !important; }
 .fullscreen {
  padding: 4% !important; }
 .LogicPic {
  flex-direction: column; }
 h5 {
  margin: 0;
  padding: 2%; }
 .legendChild {
  display: flex;
  width: auto;
  height: auto;
  align-items: center;
  flex-direction: column;
  font-size: 11px;
  font-weight: 800;
  padding: 4%; }
 .legendcircle {
  width: 7vw; }
 .LedShadow {
  cx: 7vw;
  cy: 7vw;
  r: 6.45vw; }
 .dropdown-item {
  font-size: 11px; }
 .Error {
  padding: 1%;
  font-size: 11px; }
 .helptitle {
  font-size: 12px;
  font-weight: 900; }
 .descriptionInput {
  font-size: 11px !important; }
 .titleApp {
  font-size: 16px;
  width: 75%;
  text-align: center;
  padding: 2%;
  margin-top: 0%; }
 .SubtitleApp {
  font-size: 13px; } }
