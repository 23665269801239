.terminalsquantityInfo {
 display: flex;
 width: 100%;
 height: auto; }
.terminalsquantityInfo-component {
 width: auto;
 height: auto; }
.Coordenatesdata {
 display: flex;
 width: 40%;
 height: auto; }
