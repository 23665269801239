.SmallOutputInfo {
    margin-left: 0.2vw;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem; }

.SmallOutputActive {
	animation: heartbeat 1.5s ease-in-out infinite both; }

.SmallOutputStopped {}

.SmallOutputPaused {}
