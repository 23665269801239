.categoryOutput {
 display: flex;
 width: auto;
 background: #4180FF;
 color: white;
 border-radius: 2.5em;
 padding: 0% 9% 0% 9%;
 align-items: center;
 justify-content: center;
 border: 0; }
.ChangeActivationType {
 display: flex;
 width: 66%;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.outputModified {
 border: 1px solid #4180ff;
 margin-bottom: 1%; }
.outputClick {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-evenly;
 align-items: center; }
.outputClick.reverse {
 flex-direction: row-reverse; }
.buttonInfoModifiedOutput {
 fill: #4180FF;
 width: 22px; }
.icoModifiedOutput {
 width: 9%;
 height: auto; }
.outputsT {
 display: flex;
 width: 100%;
 height: auto;
 flex-direction: column;
 margin-top: 1%;
 } //MVL
@media (max-width: 480px) {
 .icoModifiedOutput {
  width: 11%; }
 .buttonInfoModifiedOutput {
  fill: #4180FF;
  width: 18px; } }
