@import "../../../../../../../App.sass";

.IcoDelProgram {
  width: 1.6vw; }
.horizontal-menu {
  width: 84%; }
.menu-wrapper {
  width: 100%;
  height: 100%; }

.menu-wrapper--inner {
  //margin-left: 10%
  width: 100% !important;
  text-align: unset;
  display: inline-flex;

  height: auto; }
.staged-outer {
  width: 80%; }
.staged {
  display: flex; }
.menu-wrapper {
  border: none !important; }
.menu-item-wrapper {
  outline: none; }
.centerp {
  display: flex;
  width: 86%;
  height: auto;
  justify-content: end;
  flex-direction: column;
  text-align: center;
  margin: auto;
  min-width: 6vw; }
.scroll-auto {
  -webkit-overflow-scrolling: touch; }
.IcoDeleteProgram {
  position: absolute;
  margin-left: 102%;
  top: -25%; }
.IcoPending {
  position: absolute;
  right: -30%;
  top: 69%; }
.TopRigthElement {
  position: absolute;
  right: -13%;
  top: -20%; }
.FixScroll {
  display: flex;
  width: 94%;
  height: auto;
  align-items: center;
  font-weight: 500;
  color: gray;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  min-height: 8vw; }

.Programs {
  display: flex;
  width: 100%;
  height: auto; }
.nameprogramc {
  margin-right: 0.5vw;
  margin-left: 0.5vw; }

.ButtonProgram {
  width: 2.5vw;
  height: auto;
  cursor: pointer; }

.ButtonProgram.Add {
  fill: $ColorPrincipal; }
.ButtonProgram.Disabled {
  fill: $ColorDeactivated;
  cursor: not-allowed; }
.ButtonProgram.Info {
  fill: $ColorPrincipal;
  width: 20px; }
.ButtonProgram.InfoAdvanced {
  fill: $ColorPrincipal;
  width: 21px; }
.ButtonProgram.Delete {
  padding: 1%;
  margin-left: 1%;
  margin-top: 2%; }
.ButtonProgram.Disabled {
  fill: $ColorDeactivated;
  cursor: not-allowed; }

.ColorBarProgram {
  width: 100%;
  background: rgb(29,97,176);
  height: 0.3vw; }
.ColorBarProgram.selected {
  background: rgb(29,176,74); }
.ColorBarProgram.disabled {
  background: $ColorDeactivated; }

.flexProgram {
  display: flex;
  width: 96%;
  height: auto;
  justify-content: space-between;
  align-items: center;
  padding: 0.5%;
  color: gray; }

.Barra {
  width: 100%;
  margin: 0;
 }  //Movil
@media (max-width: 480px) {
 .ButtonProgram.InfoAdvanced {
  width: 28px; }
 .IcoDeleteProgram {
  margin-left: 76%;
  top: -27%; }
 .IcoDelProgram {
  width: 18px; }
 .FixScroll {
  -webkit-overflow-scrolling: touch;
  padding:  0;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0% 0% 9px 0%;
  min-height: 23vw; }

 .ColorBarProgram {
  width: 100%;
  background: rgb(29,97,176);
  height: 1vh; }
 .ProgramsC {
  display: inline;
  width: 15vw;
  height: auto;
  font-size: 11px;
  font-weight: 900;
  margin-right: 3%;
  border: 1px solid #dee2e6; }

 .ProgramsC.selected {
  box-shadow: 0px 1px 7px #dee2e6;
  font-size: 12px; }
 .centerp {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 11px;
  min-width: 13vw; }
 .flexProgram {
  padding: 1%; }
 .AddProgram {
  width: 12vw; }
 .Programs::-webkit-scrollbar {
  display: none; }
 .ButtonProgram {
  width: 9.5vw; }
 .Programs {
  //min-height: 33vw
 }  //position: fixed
 .DelProgram {
  width: 10.5vw;
  height: auto;
  padding: 1%;
  margin-left: 1%;
  margin-top: 2%; }
 .menu-wrapper--inner {
  width: 100% !important; }
 .scroll-auto {
  -webkit-overflow-scrolling: auto; } }
