.titleMultipleStatistics {
 display: flex;
 width: 30%;
 height: auto;
 align-items: center;
 margin-left: 2%; }
.selectorday {
 display: flex;
 height: auto;
 align-items: center;
 justify-content: center;
 align-items: center; }
.tsmDay {
 width: 100%;
 margin-left: 0; }
/*MVL*/
@media (max-width: 480px) {
 .selectorday {
  width: 100%;
  margin-left: 0;
  margin-left: 5%; } }
