/*Esta clasee esta especializada solamente en los tamaños correctos de la app tanto mvl como pc: */
//Clases mejoradas y separadas:
//Logo tecatel menu burger */

//  //Clases mejoradas y separadas:

//Logo tecatel menu burger
.form-control {
 } //font-size: 1vw !important
.NameDevice {
 margin-bottom: 4%;
 width: 95%;
 height: auto;
 margin-left: 2.5%; }
#upload2 {
 cursor: pointer; }
.subtitleapp {
 padding: 1%; }
.msgCancelOutput {
 position: absolute;
 width: 48%; }
.ImageCancelOutput {
 width: 12.5vw;
 height: 2.8vw;
 opacity: 0.6; }

/*MVL*/
@media (max-width: 480px) {
 .msgCancelOutput {
  width: 29vw; }
 .ImageCancelOutput {
  width: 28.1vw;
  height: 8.8vw; }
 .DescriptionOutput {
  font-size: 11px; }

 .form-control {
  font-size: 11px !important; } }
