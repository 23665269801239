
.idsize {
 width: 15% !important; }
.descriptionsize {
 width: 52%;
 word-wrap: break-word;
 padding: 1%; }
.numbersize {
 width: 30%;
 word-wrap: break-word;
 padding: 1%; }
.title2 {
 width: 93%;
 margin: auto;
 margin-top: 2%; }
.KeyShow {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 color: #8493a5; }
.KeyShowC {
 display: flex;
 width: 50%;
 height: auto;
 justify-content: center;
 align-items: center; }
