@import "../../../../../../../App.sass";

.ProgramCDeleteSwing {}


.ProgramsC {
  display: flex;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  align-items: center;
  width: 5vw;
  height: auto;
  font-size: 1.4vw;
  font-weight: 900;
  cursor: pointer;
  margin-right: 3%;
  flex-direction: column;
  position: relative;
  justify-content: center;
  scroll-margin-top: 15vh;
  scroll-snap-margin-top: 15vh;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #dee2e6; }

.ProgramsC.selected {
  border: 2px solid #e9ecef;
  box-shadow: 0px 1px 7px #dee2e6;
  font-size: 1.5vw; }
.ProgramsC.modified {
  border: 1px solid red; }
.ProgramsC.disabled {
  cursor: not-allowed; }
