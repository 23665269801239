.CardToken {
  border: 1px solid #dee2e6;
  padding: 1%;
  width: 100%;
  margin: 1%; }

.LogoCopiedToken {
 width: 2.3em;
 height: auto;
 cursor: pointer; }
.fechasPaso2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: auto; }

/*MVL*/
@media (max-width: 480px) {
 .ButtonToken {
   width: 39%;
   height: 100%; }
 .CardToken {
   width: 98%;
   height: auto; } }
