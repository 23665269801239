.cardSavePostigel {
 display: flex;
 width: 41%;
 height: auto;
 justify-content: space-between;
 align-items: center;
 padding: 2%;
 background: #ececec; }

.titleSavePostigel {
 font-size: 1.2vw;
 font-weight: bold;
 margin-left: 3%; }
.cardsSavePostigel {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-around;
 padding: 2%; }
.selectorsDaysBatery {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 margin-left: 0%;
 padding: 2%;
 justify-content: center; }
.dayInDayBatery {
 //color: #2372b1
 color: #434d56;
 text-align: center;
 margin-left: 8%;
 margin-right: 8%;
 font-weight: 600;
 font-size: 1.2vw; }
.icoSelectDayBatery {
 background: #007bff;
 padding: 2%;
 border-radius: 50%;
 color: white !important;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 8% !important; }
.selectorDatef {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }

/*MVL*/
@media (max-width: 480px) {
 .dayInDayBatery {
  font-size: 18px; }
 .selectorDatef {
  width: 100%;
  margin-left: 0; }
 .cardSavePostigel {
  width: 100%;
  padding: 4%;
  margin-bottom: 1%; }

 .titleSavePostigel {
  font-size: 16px; }
 .cardsSavePostigel {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 0; } }
