
@font-face {
  font-family: 'helveticaneue';
  src: local('helveticaneue'), url(./components/fonts/helveticaneue-roman.otf) format('truetype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9FAFC !important;
  font-family: 'helveticaneue' !important;

}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
