.contentCalibratewithInput {
 flex-direction: column; }
.widthpersonalizedCalibrate {
 width: 18%; }
.widthErrorCalibrate {
 display: flex;
 width: 100%;
 padding: 2%;
 align-items: center;
 justify-content: center;
 color: red;
 font-size: 1vw; }
.contentPopupCalibrate {
 font-size: 1vw; }
.calibrateInputGroup {
 padding: 3% !important;
 background-color: #e9ecef;
 border: 1px solid #ced4da;
 border-radius: 0 .25rem .25rem 0 !important;
 text-align: center;
 width: 26%;
 height: auto;
 align-items: center; }
.inputCalibrate {
 display: flex;
 flex-wrap: nowrap;
 justify-content: center;
 align-items: stretch !important;

 /*MVL*/ }
@media (max-width: 480px) {
 .contentPopupCalibrate {
  font-size: 11px; }
 .widthpersonalizedCalibrate {
  width: 27%;
  height: 15vw;
  display: flex;
  align-items: center;
  justify-content: center; }
 .widthErrorCalibrate {
  font-size: 11px; } }
