.Buttons {
 display: flex;
 flex-wrap: wrap;
 width: 100%;
 justify-content: space-between;
 align-items: center;
 margin-top: 0.5rem;
 margin-bottom: 0.5rem; }
.Buttonsf {
 //padding: 3%
 display: flex;
 width: auto;
 align-items: center; }
.Pad {
 padding: 3%; }
