
.confiterminal {
 min-height: 30vw;
 display: flex;
 flex-direction: column; }

.AccordionsShareFile {
 display: flex;
 width: 97%;
 height: auto;
 justify-content: space-between;
 align-items: center;
 margin-left: 1%;
 margin-bottom: 1%; }
.contentAccordionsTools {
 display: flex;
 width: 98%;
 height: auto;
 justify-content: center;
 align-items: center; }
.buttonsecConf {
 font-size: 1vw;
 display: flex;
 border-radius: 2.5em;
 cursor: pointer;
 color: #4180FF;
 border: 2px solid #4180FF;
 box-shadow: 2px 3px 7px #dee2e6;
 background: white; }
.heightButtonConf {
 margin-top: 2vw;
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 justify-content: center;
 flex-direction: column; }
.serienumber {
 height: auto;
 color: grey;
 display: flex;
 justify-content: flex-end;
 font-size: 0.6rem; }
.IcoInfo {
 width: 2vw;
 height: auto;
 fill: #49358f;
 cursor: pointer; }
.buttonSecondaryTerminalDeviceConf {
 display: flex;
 width: 60%;
 height: auto;
 justify-content: center;
 align-items: center; }
.messagesSavesConf {
 display: flex;
 width: 96%;
 margin-left: 2%;
 height: auto;
 justify-content: center;
 align-items: center;
 font-size: 1vw;
 font-weight: 300;
 color: #8493a5;
 flex-direction: column;
 margin-top: 4%; }
.messagesSavesConfcolumn {
 display: flex;
 width: 81%;
 height: auto;
 justify-content: space-evenly;
 align-items: center; }
.messagesSavesConfc {
 display: flex;
 width: 60%;
 height: auto;
 padding: 1%;
 flex-direction: column;
 margin-left: 15%; }
.messageSavesTitleConf {
 font-weight: 600; }
@media (max-width: 480px) {
 .messagesSavesConf {
  font-size: 11px; }
 .buttonsecConf {
  width: 100%;
  font-size: 10px; }
 .IcoInfo {
  width: 7vw;
  height: auto;
  fill: #49358f;
  cursor: pointer; }
 .confiterminal {
  min-height: 0vw;
  justify-content: normal; }
 .heightButtonConf {
  margin-top: 4%; } }

