.IcoDeactivate {
 fill: red;
 width: 2vw;
 height: auto; }
.Dragdrop {
 width: 92vw;
 margin: auto;
 height: auto;
 display: flex;
 justify-content: space-between; }
.DragDropProvided.prepost {
 display: flex;
 align-items: center;
 justify-content: center;
 height: 9vw;
 position: fix;
 cursor: pointer;
 width: 6%;
 background: #4180ff;
 color: white; }
.DragDropProvided.Main {
 display: flex;
 height: auto;
 width: 100%;
 background: #c1c0c4;
 justify-content: inherit;
 overflow-x: scroll;
 overflow-y: hidden; }


.DragDropSelect {
 margin: 1%;
 background: #8f5e35;
 color: black;
 position: fixed;
 //padding: 2%
 //top: 0 !important
 //margin: 1%
 } //left: 10% !important

.deactivateDrag {
 cursor: default !important; }
.DragDropNotSelect {
 width: 100%;
 } //color: black
// margin: 0%
///background: #49358f

.titleDragDrop {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: flex-end;
 padding: 2% 2% 0% 0%;
 background: #716f79; }
.titleDragDropwithError {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 padding: 2% 2% 0% 0%;
 background: #716f79; }

.IcoDrag {
  width: 2vw;
  height: auto;
  fill: #e6e6e9; }
//Movil
@media (max-width: 480px) {
 .DragDropProvided {
  height: 25vw; }
 .IcoDrag {
  width: 10vw; }
 .IcoDeactivate {
  width: 10vw; }
 .DragDropNotSelect {
  width: 25vw;

  //color: black
  //margin: 1%
  //background: #49358f
 }  //min-width: 15vw
 .DragDropProvided {
  margin-top: 0.5%;
  width: 12%;
  background: #4180ff;
  color: white;
  font-size: 10px; }
 .DragDropSelect {
  width: 25vw; }
 .DragDropProvided.prepost {
  width: 13%;
  height: 25vw; } }
