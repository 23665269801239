.subhelptitlewithimage {
 display: flex;
 width: 90%;
 height: auto;
 align-items: center; }
.subhelptitlewithimageCard {
 display: flex;
 width: 30%;
 height: auto;
 align-items: center;
 color: black;
 justify-content: space-between;
 //box-shadow: 2px 3px 7px #dee2e6
 box-shadow: 2px 3px 12px #848484;
 border-radius: 0.25rem;
 cursor: pointer;
 background: #f9fafc;
 margin-left: 3%;
 } //line-height: 1.2
.subhelptitleFather {
 display: flex;
 width: 100%;
 height: auto;
 flex-direction: column;
 margin-left: 3%; }
.iconDropdownHelp {
 margin-left: 1%;
 margin-right: 1%;
 font-size: 1.5vw;
 color: #346294 !important; }
.fixPositionHelp {
 width: 85%;
 display: flex;
 justify-content: space-between;
 align-items: center;
 flex-direction: column; }
.cardsHelpAccordion {
 display: flex;
 width: 100%;
 height: auto;
 margin-top: 2%;
 padding: 1%; }
.titlehelpDescriptionPostigel {
 height: 3.2vw;
 text-align: center; }
.titlehelpPostigel {
 margin-left: 4%;
 } //MVL
@media (max-width: 480px) {
 .titlehelpDescriptionPostigel {
  height: 8.2vw;
  margin-left: 0;
  text-align: center; }
 .titlehelpPostigel {
  font-size: 15px !important; }
 .subhelptitlewithimageCard {
  width: 45%;
  margin-left: 1%;
  height: auto;
  justify-content: space-between;
  margin-bottom: 3%; }
 .cardsHelpAccordion {
  flex-wrap: wrap;
  justify-content: space-evenly; }
 .iconDropdownHelp {
  font-size: 3vw; } }
