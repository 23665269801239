.NotificationCount {
 display: none; }
.top-right {}

.notification__item {}

.notification {}

.notification__close {
 background-color: #CC0000 !important; }
.notification__item--info .notification__timer {
 background-color: #49358f; }
.notification__item--info {
 //background-color: #ced4da
 background-color: #f9fafc;
 border-left: 8px solid #49358f; }
.notification__title {
 color: black; }
.notification__message {
 color: black; }
.notification__timer-filler {
 background-color: #ced4da !important; }
.notification__content {
 box-shadow: 2px 3px 7px #1f2d3d; }

/*MVL*/
@media (max-width: 480px) {
 .notification__item {
  width: 96%;
  margin-left: 9%; } }
