@import "./../../../../App.sass";

.TabOutputConf {
  width: 90%;
  margin: auto;
  min-height: 0.2vw;
  background: $ColorPrincipal;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }
.titleModesActivationConf {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 font-weight: 500;
 padding: 1%;
 color: #868e96; }
.Titlewithinfo {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
  color: #8493a5; }
.icoOutputconf {
  width: 18%; }
.Title_DescripctionConf {
  width: auto; }
.icoOutputconf2 {
  width: auto; }
.ButtonMaintenance {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-end; }
.ButtonMaintenance2 {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between; }
.twoico {
  display: flex;
  width: auto;
  height: auto;
  flex-direction: row;
  align-items: flex-end; }
.Colorsvg {
  fill: $ColorPrincipal; }
.MessageSendConfiguration {
  width: 100%;
  margin-top: .25rem;
  font-size: 0.75rem;
  margin-left: 2%; }
.cardDescription {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
 }  //font-size: 2vw
.filaSalidas {
  display: flex;
  width: 100%;
  align-items: center; }
.doubleButton {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center; }
.filaSalidas2 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center; }
.MessageinMaintenance {
  position: absolute;
  display: flex;
  width: 16vw;
  height: 100%;
  justify-content: center;
  align-items: baseline;
  bottom: 0;
  opacity: 48%; }

.MessageinMaintenanceChild {
 width: 55.5vw;
 height: auto;
 cursor: pointer;
 display: flex; }
.Configuration {
 display: flex;
 flex-direction: column;
 width: 98%;
 margin-left: 1%; }
.fatherPrograms {
 display: flex;
 flex-direction: column;
 height: auto;
 margin-left: auto;
 margin-right: auto; }
.InputComplet {
 width: 100%;
 height: auto; }
.Dropdown-control {
 height: 40px; }
.react-card-flipper {
 padding: 2%; }
.NotificationTerminalDevices {
 min-height: 30vw; }
.InputForm {
 width: 100%; }
.flex1 > .Bold {
 width: 100% !important;
 font-weight: 900 !important;
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 2%; }
.flex1 {
 display: flex;
 flex-direction: row;
 width: 100%;
 height: auto;
 justify-content: space-evenly;
 align-items: center;
 margin-top: 3%; }
.ButtonsChangeAnimation {
 display: flex;
 width: 98%;
 height: auto;
 justify-content: space-between;
 align-items: center;
 padding: 1%; }
.MessageError {
 font-weight: 500;
 margin-right: 2%;
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-around; }
.react-toggle--checked .react-toggle-track {
 background-color: $ColorPrincipal !important; }
.react-toggle--checked .react-toggle-thumb {
 border-color: $ColorPrincipal !important; }
.DetailsToggle {
 font-weight: 500; }
.TogglewithInfo {
 display: flex;
 width: 50%;
 justify-content: space-around;
 align-items: center;
 padding: 1%; }
.textErrorM {
 width: 77%;
 height: auto; }
.widthAuto {
 width: 15vw;
 font-weight: 500; }
.infoClock {
 display: flex;
 width: 30%;
 height: auto; }
.infoClock_title {
 display: flex;
 width: 63%;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.toggleVoltage {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: center; }
.backico {
  font-size: 3.3vw !important;
  height: auto;
  cursor: pointer; }
.PicPlugTD {
  width: 2.5vw;
  height: auto;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.5rem; }
.PicPlugTD.Small {
  width: 2vw; }
.ButtonOffAllDouble {
  width: 42% !important; }
.ButtonOffAll {
  display: flex;
  width: 45%;
  background: $ColorPrincipal;
  color: white;
  border-radius: 2.5em;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 1%;
  border: 0;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  min-width: 125px; }
.ButtonOffAll.Danger {
  background: white;
  color: $DangerColor;
  border: 2px solid $DangerColor;
  box-shadow: 2px 3px 7px #dee2e6; }
.ButtonOffAll.Secondary {
  background: white;
  color: $ColorPrincipal;
  border: 2px solid $ColorPrincipal;
  box-shadow: 2px 3px 7px #dee2e6; }
.little {
 width: 40%; }



.ButtonOffAll:disabled,
.ButtonOffAll[disabled] {
  background: $ColorDeactivated;
  cursor: not-allowed; }
.react-toggle {
  display: flex; }
.react-toggle-screenreader-only {
  width: 20vw; }
.iconf {
 }  //font-size: 5vw !important
.flex {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: inherit; }
.MensajesMqtt {
  width: 100%;
  margin-bottom: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; }
.MensajesMqtt_head {
  width: 92%;
  display: flex; }

.nserie {
  display: flex;
  flex-direction: 100%;
  justify-content: flex-end;
  height: auto;
  width: 60%;
  font-size: 0.8vw; }
.TitleTd {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 padding: 1.75%; }
.TitleAndButton {
  display: flex;
  width: auto;
  height: auto;
  display: flex;
  text-align: center;
  justify-content: space-between; }
.TitleAndButton2 {
  display: flex;
  width: 21%;
  height: auto;
  display: flex;
  text-align: center;
  justify-content: space-between; }
.TitleTerminal {
  color: white;
  background: $ColorCardTitles;
  padding: 2%;
  width: 100%;
  height: auto;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center; }
.Title_Descripction {
  font-weight: 500; }
.Terminals {
  display: flex;
  width: 31.33%;
  height: auto;
  margin-left: 1%;
  margin-right: 1%;

  justify-content: flex-start;
  border-radius: .25rem;
  flex-direction: row;
  border-style: solid;
  border-color: #e9ecef;
  justify-content: normal;
  flex-direction: column;
  //font-size: 1vw
  min-height: 60vh;
  min-width: 30vw; }
.LedSVGOutput {
  cx: 1vw;
  cy: 1vw;
  r: 1vw;
  filter: drop-shadow(300px 100px 400px rgba(8,252,114,1));
  box-shadow: 43px 21px 73px 0px rgba(8,252,114,1); }
.LedSVGOutputTerminals {
  cx: 1.5vw;
  cy: 1.5vw;
  r: 1vw;
  filter: drop-shadow(300px 100px 400px rgba(8,252,114,1));
  box-shadow: 43px 21px 73px 0px rgba(8,252,114,1); }

.LedSVG {
  cx: 1vw;
  cy: 1vw;
  r: 1vw;
  filter: drop-shadow(300px 100px 400px rgba(8,252,114,1));
  box-shadow: 43px 21px 73px 0px rgba(8,252,114,1) {
    //stroke: white
 } }    //stroke-width: 2

.descriptionTerminals {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 15.2vw; }

.descriptionDevicesTerminals {
  display: flex;
  width: 100%;
  height: auto;
  color: rgb(166,163,168);
  flex-direction: column; }
.NumberOutput {
  display: flex;

  //Si se pone en auto se descuadra las salidas
  width: 28%;
  font-weight: 500;
  justify-content: center; }
//.Output > .DescriptionOutput

.DescriptionOutput {
  display: flex;
  //Si se pone en auto se descuadra las salidas
  width: 75%;
  font-weight: 400;
  height: auto;
  overflow: hidden;
  align-items: center;
  flex-direction: column; }
.DescriptionOutputModified {
  display: flex;
  width: 70%;
  font-weight: 400;
  height: auto;
  overflow: hidden;
  align-items: center;
  flex-direction: column; }
.outputModified > .outputClick > .DescriptionOutputModified {

  width: 69%;
 }  //font-size: 1.5vh
.DescriptionOutputchildrencenter {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  text-align: center; }

.OutputChild {
  width: 100%;
  justify-content: flex-end;
  align-items: center; }
.Output > .OutputChild {
  display: flex;
  flex-direction: row-reverse; }
.NumberInside {
  fill: white;
  fill: white;
  stroke-width: 1.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px #060606;
  font-size: 1.2vw; }
.Output2 > .OutputChild {
  display: flex; }
.NotificationTerminalDevices {
  display: flex;
  width: auto;
  //min-height: 39vh
  align-items: center;
  flex-direction: column;
  height: auto; }
.FilaOutputs {
  display: flex;
  width: 100%;
  height: 20vw;
  //flex-wrap: wrap
  //justify-content: space-between
  align-items: center;
  cursor: pointer;
  flex-direction: column; }
.Output2 {
  padding: 0.8%;
  display: flex;
  width: 50%;
  cursor: pointer;
  align-items: center; }
.Output {
  padding: 0.8%;
  display: flex;
  width: 50%;
  flex-direction: row-reverse;
  cursor: pointer;
  display: flex;
  align-items: center; }
.MessageUser {
  width: 95%;
  justify-content: space-around;
  align-items: center;
  height: auto;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: .25rem;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  padding: 2%;
  border-color: #e9ecef;
  min-height: 47vh; }
.MessageUserChild {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: end; }
.MessageUserActive {
  display: flex;
  width: 50%;
  height: auto;
  align-items: center;
  justify-content: center; }
.MessageUserActive > .ButtonPanel {
  width: 50%; }
.MessageUserChild2 {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2%; }
.MessageUserChild3 {
  width: auto;
  display: flex; }
.svgCircle {
   width: 3vw;
   height: 3vw; }
.click {
  cursor: pointer; }
.configurationSensors {
  display: flex;
  flex-direction: column;
  width: 98%;
  margin-left: 1%; }

/*MVL*/
@media (max-width: 480px) {
  .configurationSensors {
    width: 100%;
    justify-content: space-evenly;
    margin: 0; }
  .TabOutputConf {
    width: 92%;
    min-height: 0.5vw; }
  .fatherPrograms {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
    align-items: baseline;
    margin: 0; }
  .Dropdown-control {
    height: 40px; }
  .toggleVoltage {
   flex-wrap: wrap; }
  .infoClock_title {
    width: 49%; }
  .infoClock {
    width: 40%; }
  .PicPlugTD {
    width: 9vw;
    height: auto; }
  .PicPlugTD.Small {
    width: 6vw; }
  .ButtonOffAll {
    width: 49%;
    font-size: 11px;
    padding: 2%; }

  .NotificationTerminalDevices {
    padding: 0;
    display: flex;
    width: auto;
    min-height: auto;
    align-items: center;
    flex-direction: column; }
  .iconf {
 }    //font-size: 10vw !important
  .MessageUserChild {
    width: 96%; }
  .FilaOutputs {
    display: flex;
    width: 100%;
    margin-top: 0%; }
  .descriptionTerminals {
   width: 45vw;
   color: #050505;
   min-height: 47vw; }
  .descriptionDevicesTerminals {
   height: auto; }
  .Terminals {
    width: 100%;
    margin: 0;
    flex-direction: column;
    min-height: 0; }
  .LedSVG {
    cx: 2vw;
    cy: 2vw;
    r: 2vw; }
  .LedSVGOutput {
    cx: 3vw;
    cy: 3vw;
    r: 3vw; }
  .LedSVGOutputTerminals {
    cx: 7vw;
    cy: 7vw;
    r: 5.5vw; }
  .Title_Descripction {
    margin-right: 0; }
  .NotificationSector > .svgCircle {
    width: 6vw;
    height: 6vw; }
  .DetailsToggle {
    font-weight: 800; }
  .NumberInside {
    text-shadow: 1vw 1vw #060606;
    font-size: 7vw; }
  .svgCircle {
    width: 14vw;
    height: 14vw; }
  .flex1 {
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    align-items: center;
    margin-top: 0%; }
  .MessageinMaintenance {
    width: 100%; }
  .DescriptionOutput {
    height: auto; }
  .DescriptionOutputModified {
    width: 77%;
    height: auto; }
  .backico {
    font-size: 33px !important; }
  .FilaOutputs {
    height: auto; }
  .nserie {
    width: 60%;
    font-size: 3vw; }
  .NumberOutput {
    width: 34%; } }
