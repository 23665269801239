.formInputs {
 display: flex;
 flex-direction: column;
 width: 70%;
 height: auto;
 margin: auto; }
.UploadPic {
 width: 70%; }
.PanelPopUp {
 padding: 2%;
 display: flex;
 flex-direction: column;
 width: 100%;
 height: auto; }
.buttonUpload {
 cursor: pointer; }
.titleapp2 {
 font-size: 1.2vw;
 font-weight: 600;
 width: 80%;
 margin: auto; }
.NumeroElementosDiv {
 font-size: 11px; }
.subtitleapp2 {
 font-size: 1vw;
 width: 80%;
 margin: auto; }
.TitleNotification {
 font-weight: 600;
 margin-bottom: 2%; }
.TitleNotification2 {
 font-weight: 600; }
.ImagePreview {
 display: flex;
 width: 100%;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 cursor: pointer; }
#upload2 {
 opacity: 0;
 position: absolute;
 height: 100%;
 width: 100%; }
.MarcoFoto {
 //background-image: url('https://cdn2.iconfinder.com/data/icons/budicon-document-2/16/69-document_-_attachment_clip_paperclip-512.png');
 background-repeat: no-repeats;
 background-size: contain;
 border: #49358f 1px dashed;
 position: relative;
 height: 26vh;
 width: 100%;
 cursor: pointer; }
.Hidden-MarcoFoto {
 position: absolute;
 top: -80vw; }
/*MVL*/
@media (max-width: 480px) {
  .MarcoFoto {
   width: 84%;
   height: 40vw;
   display: flex;
   margin: auto; }
  .titleapp2 {
   font-size: 13px; }
  .subtitleapp2 {
   font-size: 11px; } }

