@import "./../../../../App.sass";
.TerminalsF {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 align-items: center;
 cursor: pointer; }
.TerminalsF:hover {
 background: $ColorPrincipal;
 color: white; }
.DatesPage2 {
 text-align: center;
 padding: 1%;
 width: 100%;
 font-weight: 700; }
.ListaPaso2 {
 width: 93%;
 margin: auto;
 height: auto;
 min-height: 0vh; }
.TerminalsF_selected {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;
 background: $ColorPrincipal;
 color: white;
 padding: 2%; }
.TerminalChildF {
 display: flex;
 width: 93%;
 height: 100%;
 align-items: center;
 justify-content: space-between;
 margin: auto;
 display: flex;
 color: #8493a5;
 font-weight: 500;
 padding: 0% !important; }
.TerminalChildFC {
 width: 33%; }

.FormPage2 {
 display: flex;
 width: 100%;
 height: 100%;
 justify-content: space-between;
 align-items: center; }

/*MVL*/
@media (max-width: 480px) {
 .ListaPaso2 {
    padding: 1%;
    width: 99%;
    height: auto;
    margin: auto;
    min-height: 44vw; } }
