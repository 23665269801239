.close {
 cursor: pointer;
 color: rgb(220,53,69) !important;
 position: absolute;
 left: 96%; }
.invisibleframetoClose {
 display: flex;
 width: 100%;
 height: 100vw;
 background: red;
 position: absolute;
 cursor: pointer;
 z-index: 10; }


/*MVL*/
@media (max-width: 480px) {
 .close {
  left: 92%; }
 .popup-content {
  width: 90% !important; } }

