.configuretsm_Title {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 justify-content: center;
 font-size: 1.2vw;
 font-weight: 500; }
.head_TsmConfigure {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 justify-content: center; }
.inputTSM {
 flex-direction: row-reverse;
 align-items: center;
 //width: 30%
 height: auto;
 //height: 4.1vh !important
 justify-content: center;
 cursor: pointer;
 color: black !important; }
.selectTerminalDeviceClass {
 display: flex;
 width: 86%;
 height: auto;
 margin-left: 7%;
 justify-content: space-between;
 align-items: center; }
.titleSecondaryTSM {
 padding: 1%; }
.sensorInputToolsTsm {
 padding: 1%;
 display: flex;
 width: 40%;
 background: white;
 justify-content: center;
 border: solid 1px #e9ecef; }
.TsmDropdownTool {
 width: 56% !important; }
.TsmDropdownToolDispositive {
 width: 36% !important; }
.titleSensorInConf {
 display: flex;
 width: 100%;
 justify-content: center;
 height: auto;
 align-items: center;
 padding: 1%; }
//MVL
@media (max-width: 480px) {
 .inputTSM {
  height: 41px !important; }
 .selectTerminalDeviceClass {
  padding: 2%; }
 .configuretsm_Title {
  font-size: 13px;
  font-weight: 600; }
 .sensorInputToolsTsm {
  padding: 4%; } }
