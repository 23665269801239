.sensorTSMPanel {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 flex-wrap: wrap;
 padding: 1%; }
.goToStatisticsTsm {
 display: flex;
 width: 2.7vw;
 fill: #4180FF;
 cursor: pointer;
 height: auto; }
.cardSensorTsm {
 display: flex;
 width: 98%;
 //height: 13vw
 justify-content: space-between;
 align-items: center;
 padding: 2%;
 background: #ececec;
 margin-bottom: 2%;
 flex-direction: column; }
.cardSensorTsmc {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 //align-items: center
 padding: 2%;
 margin-bottom: 2%; }

.SensorTSM {
 display: flex;
 flex-direction: row;
 width: 50% !important;
 height: auto;
 padding: 2%;
 justify-content: space-evenly;
 font-weight: 600;
 border-radius: 0.25rem;
 flex-direction: row;
 border: 2px solid #e9ecef;
 box-shadow: 2px 3px 7px #dee2e6;
 padding: 2%;
 margin-bottom: 1%;
 height: 5vw; }
.tsmBody {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: auto;
 min-height: 10vw; }
.tsmButtons {
 margin: 0 !important;
 margin-top: 5% !important; }
.ButtonTSM {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }
.ButtontsmPrincipal {
 background: #4180FF;
 color: white; }
.tsmtitleIconsHead {
 justify-content: flex-end; }
.SvgIcoRTsm {
 width: 2.8vw;
 height: auto;
 fill: #4180FF;
 cursor: pointer; }
.fSerienumber {
 display: flex;
 width: 98%;
 height: auto;
 justify-content: flex-end;
 align-items: center;
 margin-right: 2%; }
.Errortsm {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 color: red; }
.messageTsm {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }

.blinkingBackground {
 -webkit-animation: blinking-background-animation 5s infinite;
 -moz-animation: blinking-background-animation 5s infinite;
 -o-animation: blinking-background-animation 5s infinite;
 animation: blinking-background-animation 5s infinite; }

// keyframes mixin
@mixin keyframes($name) {
 @-webkit-keyframes #{$name} {
  @content; }
 @-moz-keyframes #{$name} {
  @content; }
 @-ms-keyframes #{$name} {
  @content; }
 @keyframes #{$name} {
  @content; } }

@include keyframes(blinking-background-animation) {
 0%, 49% {
  background-color: #ececec; }
 50%, 100% {
  background-color: #f5b0ab {

 } } }   //Movil
@media (max-width: 480px) {
 .SvgIcoRTsm {
  width: 22px; }
 .ButtonTSMchild {
  width: 100%;
  font-size: 10px; }
 .goToStatisticsTsm {
  display: flex;
  width: 8vw;
  height: 8vw; }
 .ButtonTypeTsm {
  width: 34vw;
  font-size: 11px; }
 .tsmButtons {
  margin: 0 !important;
  margin-top: 5% !important; }

 .sensorTSMPanel {
  min-height: 20vw;
  align-items: initial; }
 .SensorTSM {
  height: auto;
  margin-bottom: 1%; } }
