.loginTitle {
 font-size: 1.09375rem; }

.loginsubTitle {
 text-align: center;
 font-size: .875rem;
 color: #8493A5 !important; }
.titleLabels {
 font-size: .875rem;
 color: #8493A5; }
.small {
 color: #8493A5;
 font-size: .875rem; }
.margindown {
 margin-bottom: 2%; }
.centeral {
 display: flex;
 justify-content: center;
 align-items: center; }
.Alignj {
 width: 100%;
 display: flex;
 height: auto;
 align-items: center; }

//MVL
@media (max-width: 480px) {
 .small {
  font-size: 10px !important; }

 .titleLabels {
  font-size: 11px; }
 .loginsubTitle {
  font-size: 11px; } }

