@import "./../../../../../App.sass";
.imagesensorandtext {
 display: flex;
 flex-wrap: wrap;
 width: 100%;
 height: auto;
 align-items: center;
 justify-content: space-between;
 min-width: 100%; }
.sensorsCardFather {
 display: flex;
 width: 100%;
 height: auto;
 flex-wrap: wrap; }
.SensorShow {
 display: flex;
 flex-direction: row;
 width: 100% !important;
 height: auto;
 align-items: center;
 padding: 2%;
 justify-content: space-evenly;
 font-weight: 600;
 border-radius: 0.25rem;
 flex-direction: row;
 border: 2px solid #e9ecef;
 box-shadow: 2px 3px 7px #dee2e6;
 padding: 2%;
 margin-bottom: 1%; }
.sensordescription {
 text-align: center;
 width: 100%;
 height: auto; }

.barfather {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: flex-end; }
.barcolor {
 width: 0.5vh;
 height: 0.5vh;
 border-radius: 50%; }
.s0 {
 background: #33ae9a; }
.s1 {
 background: #49358f; }
.s2 {
 background: #d45fc0; }
.s3 {
 background: #6610f2; }
.s4 {
 background: #eae426; }
.s5 {
 background: #c3484d; }
.pildore {
 color: white;
 text-shadow: 2px 2px 2px black; }
.card-body-sensor {
 padding: 0;
 display: flex;
 flex-direction: column;
 width: 100%;
 height: auto; }
.titlecardsensor {
 display: flex;
 width: auto;
 height: auto;
 justify-content: space-between;
 line-height: 1.4;
 margin-bottom: 0;
 padding: 1%; }
.sensorImage {
 width: 20%;
 text-align: center; }
.badge-humidity, .badge-lit {
 background-color: #007BFF !important; }
.badge-temp {
 background-color: #CA0404 !important; }
.badge-press {
 background-color: #00C9A7 !important; }
.badge-ORP {
 background-color: #cccb35 !important; }
.badge-DO {
 background-color: #CA0404 !important; }
.badge-EC {
 background-color: #00C9A7 !important; }
.badge-electricity {
 background-color: #cccb35 !important; }
.SensorIcon {
 width: 2.5em;
 height: auto;
 margin-bottom: 2px;
 color: $ColorDeactivated; }
.SensorIconTsm {
 width: 5vw;
 margin-bottom: 2px;
 height: auto; }
.titlecardsensorname {
 font-size: 1rem;
 margin-left: 4%; }
.alarmDesactivate_SensorCard {
 display: flex;
 width: 33%;
 height: auto;
 justify-content: center;
 align-items: center;
 cursor: pointer; }
.alarmActivate_SensorCard {
 display: flex;
 width: 33%;
 height: auto;
 justify-content: center;
 align-items: center;
 cursor: pointer;

 //border: 3px solid #ed1c24
 //background: #f55858
 border-radius: .25rem !important; }
.alarmActivate_SensorCard > .Title_Descripction {
 border: 2px solid #ed1c24; }

.sizesensorsText {
  color: $Color_Letras;
  font-size: 2rem; }
.sizesensorsSubText {
  color: $ColorSecond;
  font-size: 0.8rem {

 } }   //Movil
@media (max-width: 480px) {
 .SensorShow {
  width: 100% !important;
  margin: 0 !important; }
 .titlecardsensorname {
  font-size: 0.6rem; }
 .barcolor {
  width: 100%;
  height: 0.5vw; }
 .SensorIconTsm {
  width: 14vw; }
 .sizesensorsText {
  font-size: 1rem; } }
