@import "./../../../../App.sass";
.FilaTabla > .description {
 word-break: break-all; }
.FilaTabla {
 margin-bottom: 2%;
 cursor: pointer;
 font-size: 1vw; }

.FilaTabla:hover {
 background: $ColorPrincipal;
 fill: white;
 color: white; }
.LogoCopiedTokenStep1 {
 width: 5vw;
 height: auto;
 cursor: pointer; }
.sharebutton {
 width: 7%; }
.bt-0 {
 text-align: inherit; }
.pagination {
 font-size: 1vw; }
/*MVL*/
@media (max-width: 480px) {
 .bt-0 {
  text-align: left;
  //width: 17%
  border-bottom: 0 !important; }
 .sharebutton {
  width: 16%; }
 .table td {
  padding: 0 !important; }
 .datehistoric {
  width: 48%; }
 .table tr {
  margin-bottom: 2%;
  display: flex;
  width: 100%;
  justify-content: space-between; }
 .table th {
  padding: 1% !important;
  font-size: 3.7vw; }
 .fiedTable {
  font-size: 11px;
  text-align: center; }
 .buttonCloseTable {
  font-size: 8vw;
  padding: 5%; }
 .FilaTabla {
  font-size: 11px;
  margin-bottom: 2%;
  cursor: pointer; }
 .Filatabla:hover {
  background: $ColorPrincipal; }
 .FilaTabla > .description {
  width: 37%; }
 .pagination {
  font-size: 11px;
  //margin-left: 4%
 } }  //margin-right: 2%
