.slim {
 height: 0.3rem; }
.progress {
  width: 24vw;
  margin: 2%;
  box-shadow: 1px 2px 0px #ced4da; }
.BarRemainingContent {
 display: flex;
 width: 90%;
 height: auto;
 justify-content: center;
 align-items: center;
 flex-direction: row; }
.barprogressprogram {
 display: flex;
 width: 70%;
 height: auto; }

.InfoRemainingBar {
 display: flex;
 width: 15%;
 height: auto;
 font-weight: 400; }
.ButtonsTestRemainingBar {
 display: flex;
 width: 60%;
 height: auto;
 justify-content: space-evenly;
 align-items: center; }
.ButtonRemaiBar {
 background: #4180FF;
 color: white;
 padding: 2%;
 font-weight: 900;
 border-radius: 20%;
 cursor: pointer; }
.progress-bar {
 background-color: white !important; }
.OnlyBarColor.progress {
 background-color: #4180FF !important;
 box-shadow: 1px 2px 10px 0px #8493a5; }
/*MVL*/
@media (max-width: 480px) {
  .progress {
    width: 75vw; }
  .BarRemainingContent {
    width: 95%;
    font-size: 11px; }
  .barprogressprogram {
    width: 48%; }
  .InfoRemainingBar {
    width: 52%; } }
