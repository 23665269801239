.headCard {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.titlecolour {
 color: #4180FF; }
.valuesSliderPostigel {
 display: flex;
 width: 85%;
 margin-left: 5%;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.textcardpostigel {
 font-weight: 400; }
.textcardpostigel2 {
 font-weight: 400; }
.titleEspecialpostigel {
 align-items: baseline;
 justify-content: space-between;
 height: 34px !important; }
.configurationscardsPostigel {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center; }

.recharts-label {
 font-size: 1vw; }
.ErrorPostigelpc {
 color: red;
 font-size: 1vw; }
.ErrorPostigelmvl {
 display: none; }
.CalibratePostigel {
 display: flex;
 width: 8%;
 height: auto;
 cursor: pointer;
 justify-content: center;
 align-items: center; }
.ButtonaddCalibratePostigel {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center; }
.MoreLargemvl-content {
 left: 25%; }
/*MVL*/
@media (max-width: 480px) {
 .recharts-label {
  font-size: 13px; }
 .headCard {
  height: 16vw;
  text-align: justify; }
 .valuesSliderPostigel {
  width: 86%;
  font-size: 11px;
  //margin-left: 12%
  position: absolute;
  margin-bottom: -15%; }
 .textcardpostigel {
  width: 41%;
  font-weight: 600; }
 .textcardpostigel2 {
  width: auto;
  font-weight: 600; }
 .configurationscardsPostigel {
  flex-direction: column; }
 .MoreLargemvl-content {
  height: 149vw;
  max-height: 220vw !important;
  top: 31%;
  left: 50%; }
 .headCard {
  height: auto; }
 .ErrorPostigelpc {
  display: none; }
 .ErrorPostigelmvl {
  color: red;
  font-size: 11px;
  display: flex; }
 .spacemvl {
  width: 100%;
  height: 10px; }
 .titleEspecialpostigel {
  height: auto !important; } }
