.pagePostigel {
 display: flex;
 width: 100%;
 height: auto;
 flex-direction: column;
 justify-content: center;
 align-items: center; }
.dataUiPostigelf {
 display: flex;
 width: 100%;
 height: auto;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background: #f5f5f5;
 padding: 1%;
 } //margin-top: 1%
.dataUiPostigel {
 display: flex;
 width: 94%;
 //margin-left: 10%
 height: auto;
 flex-wrap: wrap;
 justify-content: center;
 align-items: center;
 margin-bottom: 1%; }
.bannerTitlePostigelf {
 display: flex;
 width: 94%;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.bannerTitlePostigel {
 display: flex;
 width: 22%;
 height: auto;
 align-items: center;
 padding: 2%;
 font-size: 2vw;
 justify-content: center;

 } //background: antiquewhite
.titlepostigel {
 display: flex;
 width: auto;
 height: auto;
 justify-content: center; }
.cardLiquid {
 box-shadow: 2px 3px 7px #dee2e6;
 border-radius: 0.25rem;
 cursor: pointer;
 padding: 1% 0% 1% 1%;
 //background: #dee2e6
 background: white;
 display: flex;
 width: 25%;
 justify-content: center;
 align-items: center;
 //margin-left: 10%
 font-weight: 700;
 flex-direction: column; }
.cardPostigel {
 display: flex;
 width: 21%;
 flex-direction: row;
 height: auto;
 justify-content: space-between;
 //justify-content: space-around
 align-items: center;
 color: black;
 color: #8493a5;
 box-shadow: 2px 3px 7px #dee2e6;
 border-radius: 0.25rem;
 cursor: pointer;
 padding: 1% 0% 0% 1%;
 background: #f9fafc; }
.cardPostigelf {
 display: flex;
 width: 21%;
 flex-direction: row;
 height: auto;
 justify-content: space-between;
 //justify-content: space-around
 align-items: center;
 color: black;
 color: #8493a5;
 box-shadow: 2px 3px 7px #dee2e6;
 border-radius: 0.25rem;
 cursor: pointer;
 //padding: 1% 0% 1% 1%
 background: #f9fafc; }
.cardPostigelChildren {
 display: flex;
 width: 100%;
 height: auto;
 flex-direction: column; }
.cardPostigelConf {
 display: flex;
 width: 32%;
 margin-left: 1%;
 margin-bottom: 1%;
 flex-direction: row;
 height: auto;
 justify-content: space-around;
 color: #8493a5;
 border: 2px solid #e9ecef;
 box-shadow: 2px 3px 7px #dee2e6;
 border-radius: 0.25rem;
 cursor: pointer;
 flex-direction: column;
 padding: 1% 0% 1% 1%;
 font-size: 1vw; }
.cardPostigelConfespecial {
 display: flex;
 width: 33%;
 margin-left: 1%;
 margin-bottom: 1%;
 flex-direction: row;
 height: auto;
 justify-content: space-around;
 color: #8493a5;
 border: 2px solid #e9ecef;
 box-shadow: 2px 3px 7px #dee2e6;
 border-radius: 0.25rem;
 cursor: pointer;
 flex-direction: column;
 padding: 1%;
 font-size: 1vw; }


.configurationsPostigel {
 flex-direction: column; }
.subtitlePostigel {
 text-align: center;
 padding: 1%;
 font-size: 1.2vw;
 font-weight: 400; }
.spinnerPostigel {
 padding: 14% 0% 0% 0%; }
.cardsconfigurationfatherpostigel {
 display: flex;
 width: 100%;
 height: auto;
 flex-wrap: wrap; }
.buttonsActionsPostigel {
 display: flex;
 width: 15%;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.barprogressCardpostigel {
 display: flex;
 width: 80%;
 height: auto;
 justify-content: center;
 align-items: center; }
.midldlePostigel {
 display: flex;
 width: 20%;
 height: 23vw;
 color: #8493a5; }
.principalpicPosti {
 display: flex;
 width: 11%;
 height: 12vw;
 color: #8493a5; }
.dataCardpostigel {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.cardPostigelIco {
 display: flex;
 width: 30%;
 height: auto;
 justify-content: center;
 align-items: center; }
.arrowsPostigel {
 display: flex;
 width: 100%;
 height: auto; }
.centerpostigel {
 display: flex;
 width: 80%;
 height: auto;
 flex-wrap: wrap;
 justify-content: center;
 align-items: center; }
.titlepostigelImgf {
 width: 20%;
 height: auto; }
.titlepostigelImg {
 width: 4vw;
 height: auto; }
.marginbottomPostigel {
 margin-bottom: 1% !important; }
.alignicoPostigel {
 align-items: center;
 flex-direction: column;
 } //justify-content: space-around
.DivButtonsPostigel {
 margin-top: 1%;
 margin-bottom: 2%;
 width: 23%;
 height: auto;
 display: flex;
 justify-content: flex-end;
 align-items: center;
 margin-left: 7%; }
.gotoConfigurationPostigel {
 cursor: pointer; }
.PrimeraMitad {
 display: flex;
 width: 57%;
 height: auto;
 justify-content: space-between;
 align-items: center; }
.titlecardpostiwithico {
 display: flex;
 width: 57%;
 height: auto;
 //justify-content: space-between
 align-items: center; }
.buttonResetPostigel {
 display: flex;
 cursor: pointer;
 width: 7%;
 height: auto;
 align-items: center;
 justify-content: space-between; }

.icoResetPostigelf {
 display: flex;
 width: auto;
 height: auto; }
.icoResetPostigel {
 display: flex;
 width: 3vw;
 height: auto; }
.ErrosPostigel {
 display: flex;
 flex-direction: column;
 } //MVL
@media (max-width: 480px) {
 .buttonResetPostigel {
  width: 20%; }
 .icoResetPostigel {
  display: flex;
  width: 11vw;
  height: 11vw; }
 .titlecardpostiwithico {
  width: 53%; }
 .buttonsActionsPostigel {
  width: 38%; }
 .PrimeraMitad {
  width: 64%; }
 .cardLiquid {
  width: 100%;
  margin-top: 3%;
  background: #F5F5F5; }
 .DivButtonsPostigel {
  width: 49%;
  justify-content: center;
  margin-bottom: 2%;
  margin-top: 2%;
  margin-left: 7%; }
 .alignicoPostigel {
  align-items: unset; }
 .bannerTitlePostigel {
  width: 43%;
  font-size: 16px; }
 .subtitlePostigel {
  font-size: 14px;
  font-weight: 600;
  padding: 0; }
 .dataUiPostigel {
  display: flex;
  width: 100%;
  margin: 0;
  height: auto; }
 .cardPostigel {
  width: 38%;
  margin-left: 1%;
  height: auto;
  justify-content: space-between;
  margin-bottom: 1%; }
 .cardPostigelf {
  width: 39%;
  margin-left: 1%;
  height: auto;
  justify-content: space-between;
  margin-bottom: 1%; }
 .cardPostigelConf {
  margin-left: 1%;
  height: auto; }
 .cardPostigelConfespecial {
  margin-left: 1%;
  height: auto;
  width: 99%;
  margin-bottom: 0; }
 .centerpostigel {
  width: 100%; }
 .titlepostigelImg {
  width: 60px; }
 .titlepostigelImgf {
  width: 43%; }
 .midldlePostigel {
  height: 43vw; }
 .principalpicPosti {
  width: 24%;
  height: auto; }
 .dataUiPostigelf {
  margin-top: 5%; }
 .cardPostigelIco {
  display: flex;
  width: 32%;
  height: auto;
  justify-content: center;
  align-items: center; } }
