.OptionsIrrigationf {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 } //background: #6610f2
.SelectTimeProgramS.modRiego {
 //background: #575659
 //width: 20%
 border: 2px solid #e9ecef;
 box-shadow: 2px 3px 7px #dee2e6;
 border-radius: 0.25rem;
 margin-bottom: 0.5%;
 border-radius: 20px;
 color: white; }
.bodyClock {
 padding: 1%; }
.IrriElement {
 width: 33%;
 height: auto;
 background: #DEE2E6;
 color: black;
 //margin-left: 0.5%
 display: flex;
 justify-content: center;
 align-items: center;
 cursor: pointer;
 font-size: 0.8rem; }
.IrriElement.left {
 border-radius: 20px 0 0 0; }
.IrriElement.right {
 border-radius: 0 20px 0 0; }
.IrriElement.select {
 background: #4180FF;
 color: white;
 box-shadow: inset 0px 0px 2px black; }
.IrriElement:hover {
 background: #2b52a2;
 //background: white
 //color: #2b52a2
 color: white; }
