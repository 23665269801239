.ProgressView {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-around;
 align-items: center; }
.BarraProgress {
 width: 55%; }
.BarraProgress.FullWidth {
 width: 100%; }
.SuccessUploadPic {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center; }

.PicName {
 width: 100%;
 height: auto;

 //Si nombre se sale de la foto, de momento lo ocultamos:
 overflow: hidden; }
/*MVL*/
@media (max-width: 480px) {
  .PicName {
    font-size: 11px;
    width: 80%;
    margin: auto;
    height: auto; } }
