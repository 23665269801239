@import "./../../../App.sass";
.mapContainerStyle {
  //height: 77vh
  //width: 100%
 }  //margin-top: 3%
.SvgIcoR {
  width: 7vw;
  height: auto;
  fill: $Color3;
  cursor: pointer; }
.SvgIcoR:hover {
  fill: black; }
.ContainerZones {
  //font-size: 1vw
  display: flex;
  flex-direction: column;
  //padding: 2%
  //align-items: center
  justify-content: space-between;
  height: 100%; }
.svgIco {
  width: 50px; }
.TitleM {
  width: 100%;
  display: flex;
  justify-content: center;
  //font-size: 5vw
  font-weight: 400; }
.ButtonPanel {
  display: flex;
  width: 14%;
  white-space: normal;
  background: rgb(29, 97, 176);
  color: white;
  border-radius: .25rem;
  padding: 2%;
  cursor: pointer;
  //font-size: 2vw
  justify-content: center; }
.ButtonPanel2 {
  display: flex;
  width: auto;
  white-space: pre;
  background: $Color3;
  color: $Color_Letras;
  border-radius: .25rem;
  padding: 2%;
  //font-size: 2vw
  justify-content: center; }
.ButtonPanel:hover {
  background: $Color3; }
.ButtonPanel2:hover {
  background: $Color3; }
.PanelControlApi {
  display: flex;
  width: 100%;
  height: auto;
  align-items: baseline;
  justify-content: space-between;
  padding: 2%; }
.PanelControlGrid {
  display: flex;
  width: 95.5%;
  height: auto;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  position: fixed;
  background: white;
  bottom: 0; }
.ZonesDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  height: 100%; }
.LoadDiv {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
  align-items: baseline;
  justify-content: start;
 }  //margin-left: 2vh

.Zones {
  display: flex;
  width: 46%;
  height: auto;
  padding: 2%;
  margin: 2%;
  background: white;
  color: $Color_Letras;
  //font-size: 2vw
  justify-content: flex-start;
  cursor: pointer;
  border-radius: .25rem;
  flex-direction: column;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #e9ecef; }
/*MVL*/
@media (max-width: 480px) {
  .LoadDiv {
    //height: 100vw
    flex-direction: column;
    margin: 0; }
  .ButtonPanel {
    width: 30%; }
  .SvgIcoR {
    width: 15vw; }
  .Zones {
    display: flex;
    //font-size: 5vw
    width: 46%;
    min-height: 17vh; }

  .ContainerZones {
    display: flex;
    flex-direction: column;
    min-height: 76vh;
    height: 100%;
    justify-content: flex-start; }
  .TitleM {
 }    //font-size: 16vw

  .PanelControlGrid {
    width: 100%;
    margin-top: 0; } }
