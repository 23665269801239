.PanelTypesSubprograms {
 display: flex;
 width: 100%;
 height: 20vw;
 justify-content: center;
 align-items: baseline;
 margin-top: 4%; }

.PanelFatherTypes {
 display: flex;
 width: 100%;
 //height: 31vw
 height: auto;
 flex-direction: column;
 //justify-content: center
 align-items: center;
 padding: 1%;
 font-size: 1vw; }

.fatherTypesDropdown {
 display: flex;
 width: 29%;
 height: auto; }
.UiFatherTypes {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-around;
 align-items: center; }
.ConfigTypesOutputs {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;

 align-items: center;
 flex-direction: column;

 } //align-items: center
.ElementConfigType {
 width: 87%;
 font-size: 1vw; }
.pizarraTypes {
 display: flex;
 width: 100%;
 height: auto;
 margin-top: 3%;
 align-items: center;
 cursor: pointer;
 margin-left: 2%; }
.ChooseType {
 border-radius: 2.5em 2.5em 0rem 0rem;
 color: white;
 background-color: #4180FF; }
.icoDropddownMy_placeholder {
 display: flex;
 width: 97%;
 height: auto;
 align-items: center;
 justify-content: space-between;
 padding: 4%;
 color: #868e96; }
.RowUISelectTypes {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-evenly;
 align-items: center; }


.DropdownF_SelectType {
 display: flex;
 flex-direction: column;
 width: 48%;
 height: auto;
 justify-content: center;
 font-size: 1vw; }

.dropdownPanelTypeBig {
 width: 48%; }
.dropdownPanelTypeLittle {
 width: 48%; }
.widthUiMode {
 display: flex;
 width: 60%;
 height: auto;
 justify-content: center; }
.ButtonType {
 width: 14%;
 height: auto;
 margin: 1%;
 justify-content: center;
 background: #4180FF;
 border-radius: 2.5em;
 text-align: center;
 color: white;
 padding: 1%;
 font-weight: 600;
 cursor: pointer;
 font-size: 1vw; }
.titleMenuEditActivation {
 display: flex;
 width: 100%;
 justify-content: center;
 align-items: center;
 font-weight: 500;
 padding: 1%; }
.configAndButton {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 padding: 0;
 flex-direction: column;
 margin-top: 2%;
 background: #f8f9fa;
 padding: 2%; }

//MVL
@media (max-width: 480px) {
 .DropdownF_SelectType {
  height: auto; }
 .RowUISelectTypes {
  padding: 2%; }
 .ElementConfigType {
  width: 90%;
  min-height: 30vw;
  font-size: 11px; }
 .PanelFatherTypes {
  font-size: 11px;
  //height: 131vw
  height: auto;
  padding: 0;
 }  //justify-content: space-between
 .PanelTypesSubprograms {
  height: 100vw; }
 .ConfigTypesOutputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin-top: 0;
  justify-content: center;
  align-items: center; }
 .fatherTypesDropdown {
  width: 47%;
  padding: 2%; }
 .DropdownF_SelectType {
  font-size: 10px; }
 .ButtonType {
  width: 34vw;
  font-size: 11px; }
 .icoDropddownMy_Option {
  font-size: 11px; }
 .widthUiMode {
  display: flex;
  width: 100%; }
 .typesOutputsClass-content {
  max-height: 205vw !important;
  padding: 0;
  top: 32%;
  z-index: 999; } }
