@import "./../../../../../App.sass";
.mainContainer {
    border: 1px dotted $ColorPrincipal;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh; }
.mainContainer.alarms {
    border: 1px dotted $DangerColor; }

.triggerCardHeader {
    display: flex;
    align-items: baseline;
    justify-content: space-between; }
.trigger-title {
    display: flex;
    align-items: baseline; }
.triggerCardHeaderIcon {
    margin: 0.1rem; }
.triggerCardSelected {
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px; }
