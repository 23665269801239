.PicTitle {
  display: none; }
.IcoTecosidebarsvg {
  width: 1vw;
  height: auto;
  cursor: pointer; }
.PicTitle2 {
  display: flex;
  width: 92%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: 1%;
 }  //flex-direction: column
.MenuChild {
  display: flex;
  width: auto;
  height: 100%;
  justify-content: initial;
  align-items: center; }
.MenuChild2 {
  display: flex;
  width: 33%;
  height: 100%;
  justify-content: center;
  align-items: center; }
.IconMenu {
  padding: 2%;
  /*MVL*/ }

@media (max-width: 480px) {
  .MenuChild {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: inherit;
    align-items: center;
    flex-direction: row; }
  .MenuChild2 {
    padding: 2%; }
  .PicTitle {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column; }
  .PicTitle2 {
    display: none; }
  .IcoTecosidebarsvg {
    width: 5vw; } }
