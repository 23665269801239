.InfraProgramsView {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 //background: #8493a5
 color: black;
 font-size: 1vw;
 font-weight: 600px;
 flex-direction: column; }
.ButtonNextlevel {
 width: 19%;
 height: auto; }
.TitlePageInfraProgram {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 font-size: 1.2vw;
 color: #727d88; }
.NavigationCategories {
 display: flex;
 width: 96%;
 height: auto;
 align-items: center;
 justify-content: space-between; }
.ButtonCf {
 display: flex;
 width: 100%;
 height: auto;
 font-weight: 600;
 cursor: pointer; }
.Secondary2 {
 background: white !important;
 color: #4180FF !important;
 border: 2px solid #4180FF !important;
 box-shadow: 2px 3px 7px #dee2e6; }
.ButtonC {
 width: 14%;
 height: auto;
 //margin: 1%
 justify-content: center;
 background: #4180FF;
 border-radius: 2.5em;
 text-align: center;
 color: white;
 padding: 1%;
 font-weight: 600;
 cursor: pointer;
 } //barra se consume conforme avanza el tiempo
.ButtonC2 {
 width: 14%;
 height: auto;
 padding: 1%;
 //margin: 1%
 justify-content: center;
 background: #4180FF;
 border-radius: 2.5em;
 text-align: center;
 color: white;
 font-weight: 600;
 cursor: pointer;
 } //barra se consume conforme avanza el tiempo
.withNumber {
 width: 100%;
 padding: 5%; }
.fButtonSaveInfraprograms {
 width: 14%;
 height: auto;
 display: flex;
 justify-content: flex-end;
 align-items: center; }

/*MVL*/
@media (max-width: 480px) {
 .ButtonNextlevel {
  width: 32%; }
 .fButtonSaveInfraprograms {
  width: 31%;
  justify-content: center; }
 .TitlePageInfraProgram {
  font-size: 11px; }
 .NavigationCategories {
  width: 96%; }
 .ButtonC {
  width: 28%; }
 .returnfromadvanced {
  padding: 1.11%;
 }  //padding: 2.1%
 .ButtonC2 {
  width: 34%;
  padding: 1%;
 }  //padding: 2.1%
 .withNumber {
  width: 81%;
  padding: 4%; }
 .ButtonCf {
  width: 100%; } }
