.Barrita {
  width: 96%;
  margin: auto;
  margin-top: 3%;
  height: 1.5vw !important; }
.BarritaTerminals {
  width: 96%;
  margin: auto;
  margin-top: 3%;
  height: 1.5vw !important; }
.Image {
  //Cambiar tamaño cuadrado:
  width: 13vw;
  height: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  max-height: 13vw;
  pointer-events: none;
  border-radius: 19%; }



.Image.Content1 {}

.Image.Content2 {
  transform: scaleX(-1); }
.Image.Content4 {
  transform: rotate(180deg);
  transform: scaleY(-1); }
.Image.Content5 {
  transform: rotate(270deg);
  transform: scaleX(-1); }
.Image.Content7 {
  transform: rotate(90deg);
  transform: scaleX(-1); }
.Image.Content3 {
  transform: rotate(180deg); }
.Image.Content6 {
  transform: rotate(90deg); }
.Image.Content8 {
  transform: rotate(-90deg); }



.SpinnerCard {
  //Cambiar tamaño cuadrado:
  width: 13vw;
  height: 15vw;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
.ContentCardTecatel {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: end;
  min-height: 15vw;

  //height: 15vw;
  //justify-content: center
  //justify-content: center
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative; }
.NotificationTerminal {
  position: absolute;
  display: flex;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 0.4%;
  top: 5%; }
.ContentCardTecatelLoad {
  width: 100%;
  height: auto;
  //position: relative
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-height: 47vw;
  //flex-direction: column
  justify-content: center;
  min-height: 13vw;
  display: flex;
 }  //height: 40vw
.childCard {
  display: flex;
  width: auto;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
  text-align: center; }
.TitleTerminalName {
  width: 100%;
  text-align: center;
  height: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  word-break: break-word;
  //overflow: hidden
  //text-overflow: ellipsis
 }  //Icono de conexión izquierda arriba parte superior.

.Coordenates {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column; }
.CoordinatesL {
  margin-left: 4%; }

.contenttitlecard {
  display: flex;
  width: 94%;
  height: auto;
  justify-content: space-around;
  cursor: pointer;
  margin: auto;
  //margin-top: 3%
  min-height: 4vh; }
.IcoCardInfoF {
  fill: #49358f;
  width: 2vw; }
/*MVL*/
@media (max-width: 480px) {

  .TitleTerminalName {
    font-size: 3vw; }
  .IcoCardInfoF {
    width: 5vw; }
  .SpinnerCard {
    width: 100%;
    height: auto;
    cursor: pointe;
    height: 23vw; }
  .Image {
    //Cambiar tamaño cuadrado:
    width: 100%;
    height: auto;
    cursor: pointe;
    max-height: 23vw;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

  .NotificationTerminal {
    padding: 1.5%;
    top: 0; }
  .Barrita {
    width: 96%;
    margin: auto;
    margin-top: 4%;
    height: 3vw !important; }
  .BarritaTerminals {
    width: 96%;
    margin: auto;
    margin-top: 4%;
    height: 3vw !important; }
  .ContentCardTecatel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-height: 49vw;
    min-height: 27vw; }
  .ContentCardTecatelLoad {
    height: 21vw; } }

/*MVL*/
@media (max-width: 330px) {

  .TitleTerminalName {
    font-size: 3vw; }
  .Barrita {
    width: 96%;
    margin: auto;
    margin-top: 4%;
    height: 3vw !important; }
  .BarritaTerminals {
    width: 96%;
    margin: auto;
    margin-top: 4%;
    height: 3vw !important; }
  //Debe de tener un poco más de altura para que al hacer click en la barrita de progreso la imagen no se mueva
  .ContentCardTecatel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-height: 49vw;
    min-height: 27vw; }
  .ContentCardTecatelLoad {
    height: 23vw;



 } }    //justify-content: center
