@import "./../../../../../../App.sass";

.ButtonConfirmPopup {
 display: flex;
 width: 42%;
 background: $ColorPrincipal;
 color: white;
 border-radius: 2.5em;
 cursor: pointer;
 margin: auto;
 padding: 2%;
 align-items: center;
 justify-content: center;
 border: 0;
 margin-bottom: 2%; }

.textapp {
 display: flex;
 width: 100%;
 padding: 2%;
 font-size: 1.2vw; }
.contentpopup {
 display: flex;
 flex-direction: column;
 justify-content: center; }
/*MVL*/
@media (max-width: 480px) {
  .textapp {
    font-size: 4vw; } }
