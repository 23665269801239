
.HoursDisplay {
  display: flex;
  width: 33%;
  height: auto;
  justify-content: center;
  align-items: center; }
.flex2 {
  display: flex;
  width: 100%;
  height: auto; }
.fixheightHour {
 display: flex;
 width: 100%;
 height: auto;
 background: #e9ecef;
 align-items: center; }
.hourpicker {
  display: flex;
  color: #575659;
  padding: 1%;
  font-weight: 600 !important;
  cursor: pointer;
  font-size: 3.5rem; }
.contentinfo {
  display: flex;
  width: 33%;
  height: auto;
  justify-content: center;
  margin-top: 1%; }
.DataPickerV2 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  //align-items: center
  flex-direction: column;
  padding: 0; }


.HoursDisplay > .rangeSliderWrap {
  margin: 0;
  padding: 0;
  margin-bottom: 3%;
  margin-top: 3%; }
.TitleClock {
  display: flex;
  width: 15%;
  height: auto;
  color: #868e96;
  align-items: center; }
.separatorClock {
  font-size: 3vw;
  color: #49358f; }
.HourDisplayFather {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between; }
/*MVL*/
@media (max-width: 480px) {
  .HourDisplayFather {
    margin-top: 1%; }
  .TitleClock {
    margin-left: 1%; }
  .DataPickerV2 {
    width: 100%;
    padding: 2%; }
  .hourpicker {
    font-size: 35px;
    height: auto;
    padding: 0 !important;
    justify-content: center;
    height: 35px;
    padding:  0 0 10px 0; }

  .separatorClock {
    font-size: 23px; }
  .BoxDialog {
    width: 72vw; } }

