.picUpload {
 width: auto;
 max-width: 100%;
 height: auto;
 max-height: 19vw;
 margin-bottom: 2%; }
/*MVL*/
@media (max-width: 480px) {
  .picUpload {
   margin: auto; } }
