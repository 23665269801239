@import "./../../../../App.sass";
.ProgramsCardFather {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: start;
 align-items: baseline;
 flex-wrap: wrap; }
.FatherMansory {
 display: flex;
 width: 100% !important;
 height: auto;
 justify-content: flex-start;
 align-content: center; }

.ProgramsCardFathertitle {
 display: flex;
 width: 100%;
 flex-direction: column;
 height: auto;
 justify-content: start;
 align-items: center; }

.ProgramsCard {
 display: flex;
 width: auto;
 flex-direction: column;
 height: auto;
 justify-content: center;
 align-items:  center;
 color: #8493a5;
 border: 2px solid #e9ecef;
 box-shadow: 2px 3px 7px #dee2e6;
 border-radius: 0.25rem;
 cursor: pointer; }
.outputpillf {
 width: 100%;
 display: flex;
 height: auto;
 align-items: center;
 justify-content: center; }

.ProgramsCard-Program {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 justify-content: center; }
.ProgramsCard-Programs-Program {
 display: flex;
 width: 47%;
 height: auto;
 padding: 2%;
 flex-direction: column;
 justify-content: center;
 align-items: center; }
.ProgramsCard-SubPrograms {
 display: flex;
 width: 100%;
 height: auto;
 flex-wrap: wrap; }
.ProgramsCard-SubProgram {
 display: flex;
 width: 100%;
 height: auto; }
.ProgramsCard-Title {
 display: flex;
 width: 100%;
 justify-content: space-around;
 align-items: center;
 background: $ColorPrincipal;
 color: white; }
.ProgramsCard-SubProgram-Title {
 display: flex;
 width: 65%;
 height: auto;
 justify-content: space-around; }
.ProgramsCard-Programs-Hour {
 display: flex;
 width: 30%;
 height: auto;
 justify-content: center;
 align-items: center;
 text-align: center;
 font-size: 0.8vw; }
.ProgramsCard-Programs-Outputs {
 display: flex;
 width: 80%;
 height: auto;
 flex-wrap: wrap;
 align-items: center; }
.ProgramsCard-Programs-Name {
 display: flex;
 width: 20%;
 height: auto;
 flex-wrap: wrap; }
.outputpillv {
 display: flex;
 width:  14%;
 height: auto;
 margin: 1%; }
/*MVL*/
@media (max-width: 480px) {
 .ProgramsCard-Programs-Hour {
  font-size: 10px; }
 .ProgramsCard {
  width: 48vw; } }
