.dropdownMy {
 display: flex;
 width: 97%;
 height: auto;
 justify-content: space-around;
 align-items: center;
 //border-radius: 2.5em
 cursor: pointer;
 background: white;
 } //box-shadow: 2px 0.1vw 0px 1px #4180FF


.icoDropddownMy {
 font-size: 1vw;
 color: hsl(0,0%,80%) !important; }
.dropdownMyOpen {
 border: 1px solid #4180FF;
 flex-direction: column; }
.dropdownMyClose {
 border: 1px solid hsl(0,0%,80%); }
.icoDropddownMy_Options {
 display: flex;
 width: 44%;
 height: auto;
 flex-direction: column;
 align-items: center;
 position: absolute;
 top: 7.5vw;
 z-index: 999;
 box-shadow: 2px 0.1vw 0px 1px hsl(0,0%,80%);
 background: white;
 margin-top: 2%;
 font-size: 1vw; }
.icoDropddownMy_Option {
 display: flex;
 width: 100%;
 height: auto;
 align-items: center;
 padding: 2%;
 margin-top: 1%; }
.icoDropddownMy_Option:hover {
 background: #d4f1ff; }
//MVL
@media (max-width: 480px) {
 .icoDropddownMy_Options {
  top: 29vw; }
 .dropdownMy {
  height: 17vw; } }
