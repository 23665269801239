.ToggleTemp > .react-toggle--checked > .react-toggle-track {
 background: #940000 !important; }
.ToggleDetection > .react-toggle--checked > .react-toggle-track {
 background: #FF6600 !important; }
.ToggleLiquid > .react-toggle--checked > .react-toggle-track {
 background: #75A3FF !important; }
.ToggleDesinfection > .react-toggle--checked > .react-toggle-track {
 background: #02A20A !important; }
.ToggleElectricity > .react-toggle--checked > .react-toggle-track {
 background: #cccb35 !important; }
.ToggleAll > .react-toggle--checked > .react-toggle-track {
 background: #12ecf2 !important; }
.fatherTogglesStatistics {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-evenly;
 align-items: baseline;
 padding: 1%; }
.ToggleF {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center; }
.ToggleTSM {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 25%; }
.ToggleTsm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center; }

//MVL
@media (max-width: 480px) {
 .ToggleF {
  width: 33%;
  padding: 1%;
  //align-items: baseline
  align-items: center; }
 .ToggleTSM {
  width: 20%;
  padding: 1%;
  //align-items: baseline
  align-items: center; }
 .ToggleTsm {
  width: 25%; }
 .fatherTogglesStatistics {
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  align-items: baseline; }
 .DetailsToggle {
  font-size: 12px;
  text-align: center; } }
