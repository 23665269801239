@import "./../../../../../../App.sass";

.sliderMultiplier {
 padding: 20px 0 20px 0;
 display: flex;
 width: 87%;
 margin: auto;
 justify-content: center;
 align-items: center; }
.marginpulses {
 margin-bottom: 2%;
 margin-left: 12%;
 margin-top: 2%; }
.sliderMultiplierOptions {
 justify-content: space-between;
 width: 100%;
 display: flex;
 height: auto; }

.sliderMultiplierContainer {
 padding: 15px 0 10px 0; }
.sliderMultiplierOptionsInput {
 } //color: green
.custom-control-input:checked~.custom-control-label::before {
  background-color: $ColorPrincipal  !important;
  border-color: $ColorPrincipal !important;
  font-weight: normal; }

@media (max-width: 480px) {
 .sliderMultiplierContainer {
  padding: 6px 0 10px 0; } }
