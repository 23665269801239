@import "./../../../App.sass";

.childCardDevices {
  display: flex;
  width: auto;
  height: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1%; }
.childCardDevices2 {
  display: flex;
  width: 41%;
  height: auto;
  align-items: center;
  padding: 1%;
  width: 54%;
  width: 100%;
  display: flex;
  justify-content: space-between; }
.TitleSector {
  color: white;
  background: $ColorCardTitles;
  font-weight: 400;
  padding: 2%;
  text-align: center; }
.SvgIcoR2 {
  width: 3.5vw;
  height: auto;
  fill: $ColorPrincipal;
  cursor: pointer; }
.SvgIcoR3 {
  width: 4.5vh;
  height: auto;
  fill: $ColorPrincipal;
  cursor: pointer; }
.SvgIcoR4 {
  width: 3vw;
  height: 3vw;
  fill: white;
  cursor: pointer; }
.svgCircle2 {
  width: 2vw;
  height: 2vw; }
.PhotoContent {
  //width: 2vw
  height: 100%; }
.Sectors {
  display: flex;
  width: auto;
  height: auto;
  margin: 1%;
  background: $Color2;
  color: $Color_Letras;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: .25rem;
  flex-direction: column;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #e9ecef;
  justify-content: space-between;
  align-items: end;
  font-weight: 100 !important;
  border-radius: 9%; }

.cursor:hover {
  background: rgb(248,249,250); }
.descriptionSector {
  display: flex;
  width: 100%;
  height: auto;
  color: $ColorSecond;
  flex-direction: column;
  padding: 2%; }
.SizeLed {
  width: 50px;
  height: 50px; }
.NotificationSector {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  padding: 2% {
    /* flex-direction: column; */ } }
.ImageAndSector {
  display: flex;
  width: 100%;

  height: auto;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }



/*MVL*/
@media (max-width: 480px) {
  .fa-plug {
    font-size: 5vw; }
  .fa-clock-o {
    font-size: 5vw; }
  .childCardDevices {
    font-size: 5vw; }
  .childCardDevices2 {
    align-items: baseline;
    flex-direction: row;
    font-size: 11px;
    width: 54%; }

  .ImageAndSector {
    width: 100%;
    //fija el aparecer la barra de progreso
 }    //min-height: 27vw
  .SvgIcoR2 {
    width: 12vw; }
  .SvgIcoR3 {
    width: 10vw;
    height: 10vw; }
  .SvgIcoR4 {
    width: 16vw;
    height: 16vw; }
  .Sectors {
    display: flex;
    width: 46%;
    min-height: 17vh;
    //padding: 2%
    flex-direction: column; }
  .SizeLed {
    width: 1.28571em;
    height: 1.28571em; }
  .NotificationSector {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 4%; }
  .svgCircle {
    width: 10vw;
    height: 10vw;
    cursor: pointer; }
  .svgCircle2 {
    width: 6vw;
    height: 6vw; } }

