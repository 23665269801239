@import "./../../../App.sass";

.custom-badge {
 display: inline-block;
 padding: .12em .12em;
 font-size: .60em;
 font-weight: 700;
 line-height: 1;
 color: #8493a5;
 text-align: center;
 white-space: nowrap;
 vertical-align: baseline;
 border-radius: .25rem;
 width: min-content; }

.gray-borders {
 border: 1px solid #8493a5; }
