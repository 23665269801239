.slick-track {
 display: flex; }
.container-fluid {
 } //display: flex
.col-3 {}
.classscroll {
 overflow: auto;
 position: fixed;
 display: flex;
 width: 80%;

 } //max-width: 50vw
