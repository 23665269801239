@import "../../../../App.sass";

.markedSliderWrap {
 padding: 0px 20px 50px; }

.rangeSliderWrap {
 display: flex;
 width: 70%;
 height: auto;
 margin: auto;
 justify-content: center;
 align-items: center;
 } //margin-top: 9%

.markedSliderVerticalWrap {
 width: 100%;
 height: 400px;
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 20px 20px 30px 20px; }
.inlineInput {
 max-width: 70px;
 margin-left: 0.5rem; }
.rc-slider {
 height: 2vh; }
//Color del slider
.rc-slider-track {
 background-color: $ColorPrincipal !important; }
//Color de la bola del slider
.rc-slider-handle {
 border: solid 2px $ColorPrincipal !important;
 //background: $ColorPrincipal !important
 //width: 2.4vw !important
 //height: 2.4vw !important
 } //margin-top: -0.8vw !important
.types .rc-slider-handle {
 border: solid 2px #ced4da  !important;
 background: white !important;
 filter: drop-shadow(2px 2px 2px #868e96); }
@media (max-width: 480px) {
 .rc-slider-handle {
  width: 40px !important;
  height: 40px !important;
  margin-top: -19px !important; }
 .types .rc-slider-handle {
  width: 25px !important;
  height: 25px !important;
  margin-top: -10px !important; }

 .cardPostigelConfespecial .rangeSliderWrap {
  padding: 0 0 10px 0; } }
