.react-datepicker-popper {
 z-index: 9999 !important;
 } //transform: translate3d(130%, 21%, 0) !important
.react-datepicker__triangle {
 //left: 0 !important
 //margin-top: 0 !important
 } //border: 0 !important

/*MVL*/
@media (max-width: 480px) {
    .react-datepicker-popper {}
 }    //transform: translate3d(40%, 21%, 0) !important
