@import "./../../../../../App.sass";
.laststatus {
 width: 100%;
 height: auto;
 display: flex;
 justify-content: flex-end;
 color: $ColorSecond;
 font-size: 0.8vw; }
.end {
 justify-content: flex-end; }
.IcoError {
 width: 5vw;
 height: auto;
 fill: red; }
.MessageConfirmPopUp {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-between;
 align-items: center;
 flex-direction: column;
 min-height: 18vw; }
.marginf {
 margin-top: 6%; }
.MessageServerOut {
 display: flex;
 width: 91%;
 height: auto;
 //justify-content: space-between
 align-items: center; }
.buttonscolumn {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: space-evenly;
 width: auto;
 padding: 1%; }
.HourStartIn {
 display: flex;
 width: 33%;
 height: auto;
 align-items: center;
 color: #868e96; }
.pizzara {
 padding: 2%;
 pointer-events: none; }
.ProgramsViewMessage {
 display: flex;
 width: 100%;
 height: auto;
 min-height: 8vw;
 justify-content: space-between;
 align-items: center;
 flex-direction: column; }
.ProgramsView {
 display: flex;
 width: 100%;
 height: auto;
 min-height: 8vw;
 justify-content: space-between;
 align-items: center; }
.Button {
 width: 14%;
 height: auto;
 margin: 1%;
 justify-content: center;
 background: $ColorPrincipal;
 border-radius: 2.5em;
 text-align: center;
 color: white;
 padding: 1%;
 font-weight: 600;
 cursor: pointer; }
.Secondary {
 background: white;
 color: $ColorPrincipal;
 border: 2px solid $ColorPrincipal;
 box-shadow: 2px 3px 7px #dee2e6; }
.Secondary2 {
 background: white !important;
 color: $ColorPrincipal !important;
 border: 2px solid$ColorPrincipal !important;
 box-shadow: 2px 3px 7px #dee2e6;
 width: 71% !important; }
.Secondary2inf {
 background: white !important;
 color: $ColorPrincipal !important;
 border: 2px solid$ColorPrincipal !important;
 box-shadow: 2px 3px 7px #dee2e6;
 width: 100% !important;
 padding: 6%; }
.Icoback {
 width: 69%;
 height: auto; }
.Icoback > .fa {
 color: white;
 font-size: 26px; }
.Tarjeta {
 width: 20vw;
 height: auto;
 background: $ColorPrincipal;
 color: white;
 display: flex;
 justify-content: center;
 align-items: center;
 margin-left: 13%;
 margin-top: 9%; }
.Titleprograms {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-content: center; }
.Titleprograms > .fa {
 font-size: 19px;
 color: white; }
.fatherbuttonprogram {
 padding: 2%; }
.NameKeyProgram {
 width: 45% !important {
  /*MVL*/ } }
@media (max-width: 480px) {
 .Icoback > .fa {
  font-size: 31px;
  padding: 1%; }
 .fatherbuttonprogram {
  width: 14%; }
 .HourStartIn {
 }  //margin-left: 1%
 .Secondary {
  width: 40% !important; }
 .Secondary2 {
  width: 100% !important; }
 .Secondary2inf {
  width: 30% !important;
  padding: 2%; }
 .helpsecondary {
  width: 80% !important;
  font-size: 11px; }
 .Button {
  width: 19%; }
 .widthdynamic {
  width: 36% !important; }
 .PicPlug {
  width: 15vw;
  height: 11vw;
  cursor: pointer; }
 .IcoError {
  width: 15vw;
  height: auto;
  fill: red; }
 .MessageConfirmPopUp {
  min-height: 58vw; }
 .laststatus {
  font-size: 3.8vw; } }
