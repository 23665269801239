.ButtonTSMf {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }
.btFathertsm {
 width: 55%; }
.withStep {
 width: 91%;
 margin-bottom: 1%; }
.bodyCalibrateContent {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: space-evenly;
 align-items: center; }
.bodyCalibrateContentcStep2 {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center; }
.spinnerCalibrate {
 display: flex;
 width: 100%;
 height: auto;
 justify-content: center;
 align-items: center;
 margin-top: 4%;
 margin-bottom: 6%; }
.bodyCalibrateContentc {
 display: flex;
 width: 45%;
 height: 20vw; }
.buttonTSMinColumn {
 display: flex;
 width: 100%;
 flex-direction: column;
 height: auto;
 align-items: center;
 justify-content: center; }
.statisticsfatherTSM {
 display: flex;
 width: 100%;
 height: auto;
 flex-direction: column;
 justify-content: center;
 align-items: center; }
.statisticsfatherTSMc {
 display: flex;
 width: 100%;
 height: auto;
 flex-direction: column;
 justify-content: center;
 align-items: center; }
.viewLittlec {
 flex-direction: row;
 justify-content: center;
 width: 50%;
 /*MVL*/ }
@media (max-width: 480px) {
 .bodyCalibrateContentc {
  font-size: 11px;
  height: 40vw; }
 .withStep {
  width: 47%;
  margin-right: 1%;
  margin-bottom: 2%; }
 .viewLittlec {
  flex-direction: column;
  width: 100%; } }

